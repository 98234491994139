export const timeToString = (obj) => {
    let str = '';

    if (obj?.hours && obj?.hours !== 0) {
        str += `${obj.hours} hours `;
    }

    if (obj?.minutes && obj?.minutes !== 0) {
        str += `${obj.minutes} minutes`;
    }

    return str;
}