import { useState, useEffect } from 'react';

function Dropdown({ 
    title = 'Question Block', 
    canMove = true, 
    hideClose = false,
    hideInfo = false,
    open = false, 
    head, 
    children, 
    className,
    style = {}
}) {
    const [ dropdownState, setDropdownState ] = useState(false);

    const stylee = {
        transform: dropdownState ? 'rotate(180deg)' : ''
    }

    if (open) {
        return (
            <div className={`dropdown ${className ? className : ''}`} style={style}>
            <div className="dropdown__content" style={{ display: 'block' }}>
                { children }
            </div> 
        </div>
        )
    }

    return (
        <div className={`dropdown ${className ? className : ''}`} style={style}>
            { !hideInfo &&
                <div className={`dropdown__head ${className ? className + '__head' : ''}  ${className && dropdownState ? className + '--toggled' : ''}`}>
                    <div className="utils__row utils__gap--10">
                        { canMove && <img className="dropdown-head__icon" src="/assets/SVG/awesomeui-item-move.svg" /> }
                        <p>{ title }</p>
                    </div>
                    <div className="utils__row utils__gap--20">
                        { head && head }
                        { !hideClose && 
                            <div className="dropdown__toggle" onClick={(e) => {
                                if (!open) setDropdownState(!dropdownState);
                            } }>
                                <img style={stylee} src="/assets/downArrow-2.svg" />
                            </div>
                        }
                    </div>
                </div>
            }
            <div className="dropdown__content" style={{
                display: dropdownState ? 'block' : 'none'
            }}>
                { children }
            </div> 
        </div>
    )
}

Dropdown.Header = ({ children }) => {
    return (
        <div className="grant-question-block__top">
            { children }
        </div>
    )
}

Dropdown.Content = ({ children }) => {
    return (
        <div className="grant-question-block__content">
            { children }
        </div>
    )
}

export default Dropdown;