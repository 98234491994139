import { useState, useEffect } from 'react';
import dropdown from '../../../components/dropdowns/dropdown';

export default function Dropdown({ title = 'Question Block', canMove = true, canMoveProps = {}, index = 1, open = false, head, children, className }) {
    const [ dropdownState, setDropdownState ] = useState(open);

    const style = {
        transform: dropdownState ? 'rotate(180deg)' : ''
    }

    return (
        <div className={`dropdown ${className ? className : ''}`}>
            <div className={`dropdown__head ${className ? className + '__head' : ''}  ${className && dropdownState ? className + '--toggled' : ''}`}>
                <div className="utils__row utils__gap--10">
                    { canMove && <img className="dropdown-head__icon" src="/assets/SVG/awesomeui-item-move.svg" { ...canMoveProps } /> }
                    <p>{ title }</p>
                </div>
                <div className="utils__row utils__gap--20">
                    { head && head }
                    { canMove && <div className="dropdown__toggle" onClick={(e) => {
                        if (!open) setDropdownState(!dropdownState);
                    } }>
                        <img style={style} src="/assets/downArrow-2.svg" />
                    </div> }
                </div>
            </div>
            { dropdownState && children && <div className="dropdown__content">
                { children }
            </div> }
        </div>
    )
}
