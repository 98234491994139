import { useEffect } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';

import Row from '../../../../../../utils/row';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { Editor } from '@tinymce/tinymce-react'

export default function QuestionTypeCta({ 
    question,
    index,
    onQuestionChange,
}) {
    const onChange = (e, index) => {
        const { name, value } = e.target;

        const links = question?.body || [];

        if (links[index] === undefined) {
            links[index] = {
             label: null,
             link: null,
            }
        }

        links[index][name] = value;

        onQuestionChange({
            target: {
                name: 'body', 
                value: links,
            }
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="CTA Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="CTA Description">
                        <Editor
                            value={question?.description || ''}
                            onEditorChange={(value, editor) => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: value,
                                    }
                                })
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }
                            }
                        />
                    </LabelContainer>

                    { new Array(4).fill().map((_, index) => {
                        const value = question?.body?.[index];

                        const label = value?.label;
                        const link = value?.link;

                        return (
                            <Row key={index} gap="40px" wrap={true}>
                                <Input label="CTA 1 Label" grow={1} value={label} name="label" onChange={(e) => onChange(e, index)}/>
                                <Input label="CTA 1 Link" grow={1} value={link} name="link" onChange={(e) => onChange(e, index)}/>
                            </Row>
                        )
                    })}
                </>
            }/>
        </QuestionType>
    )
}