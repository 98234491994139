import { useEffect, useState } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import ContainerGrid from '../../../../../../containers/container-grid';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { Editor } from '@tinymce/tinymce-react'

export default function QuestionTypeSlidingScale({ 
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {
    const [ steps, setSteps ] = useState(question?.body?.length || 0);

    const mappedSteps = () => {
        return new Array(6).fill(0).map((_, index) => ({
            label: index + 1,
            value: index + 1
        }))
    }

    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({ 
            target: { 
                name: 'config', 
                value: config
            }
        });
    }

    const onStepsChange = (index, e) => {
        const body = question?.body !== null ? [ ...question?.body ] : [];
        const { name, value } = e.target;

        if (body[index] === undefined) {
            body[index] = {
                label: null,
                value: null,
            }
        } 

        body[index][name] = value;

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }
    
    const stepsChange = (item) => {
        setSteps(item.value);

        let body = question?.body !== null ? [ ...question?.body ] : [];

        body = body.filter((_, index) => index < item.value);

        onQuestionChange({
            target: {
                name: 'body',
                value: body,
            }
        })
    }

    const renderSteps = () => {
        const arr = new Array(steps).fill(0);

        if (arr.length === 0) return null;

        return arr.map((_, index) => {
            const step = question?.body?.[index];

            const value = questions[step?.value];

            const pickedQuestion = {
                label: value?.title,
                value: step?.value,
            }

            return (
                <LabelContainer key={index} label={`Label ${index + 1}`} grow={1}>
                    <List gap="20px">
                        <Input fill={true} value={step?.label} name="label" onChange={(e) => onStepsChange(index, e)}/>
                        <Dropdown fill={true} items={getMappedQuestions()} value={pickedQuestion} onChange={(e) => {
                          onStepsChange(index, {
                            target: {
                                name: 'value',
                                value: e?.value,
                            }
                          })  
                        }}/>
                    </List>
                </LabelContainer>
            )
        })
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <Editor
                            value={question?.description || ''}
                            onEditorChange={(value, editor) => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: value,
                                    }
                                })
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }
                            }
                        />
                    </LabelContainer>
                </>
            } right={
                <>
                    <Row wrap={true}>
                        <Dropdown label="Number of steps on scale" items={mappedSteps()} value={mappedSteps()[steps - 1]} onChange={stepsChange}/>

                        <LabelContainer label="Branching">
                            <Checkbox text="Required" 
                                checked={question.config?.branching} 
                                onChange={() => {
                                    onConfigChange({
                                        target: {
                                            name: 'branching',
                                            value: !question.config?.branching
                                        }
                                    })
                                }}/>
                        </LabelContainer>
                    </Row>

                    <Dropdown label="Next Question" value={getNextQuestion()} items={getMappedQuestions()}
                        onChange={(item) => {
                            onConfigChange({
                                target: {
                                    name: 'next_question',
                                    value: item.value,
                                }
                            })
                        }}/>
                </>
            }/>

            <ContainerGrid>
                { renderSteps() }
            </ContainerGrid>
        </QuestionType>
    )
}