import {Component} from 'react';
import Dropdown from './components/content-editor/dropdown';

import FreeContentButtons from './components/content-editor/free-content-buttons';
import ContentItem from './components/content-editor/content-item';

import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

const SortableItem = sortableElement(({params, handleFreeContentTitle, onFreeItemDelete, title, type, img, content}) => {

    let dropdownParams = {
        free: true,
        onFreeItemDelete,
        params,
        handleFreeContentTitle,
        img,
        title,
        type,
        content,
    }

    return (
        <Dropdown {...dropdownParams}>
            <ContentItem {...params}/>
        </Dropdown>
    )
});

const SortableContainer = sortableContainer(({children}) => {
    return <div class="editor-dropdown__sortable-items">{children}</div>;
});

export default class extends Component {
    constructor(props) {
        super(props);
    }

    images = {
        'text-box': '/assets/dmc-mgmt-icons/dmc-mgmt-inputs/input-text.svg',
        'text-input': '/assets/dmc-mgmt-icons/dmc-mgmt-inputs/input-text.svg',
        'html': '/assets/dmc-mgmt-icons/dmc-mgmt-inputs/input-html.svg',
        'image': '/assets/dmc-mgmt-icons/dmc-mgmt-inputs/input-image.svg',
        'gallery': '/assets/dmc-mgmt-icons/dmc-mgmt-inputs/input-gallery.svg',
        'video': '/assets/dmc-mgmt-icons/dmc-mgmt-inputs/input-video.svg',
    }

    handleMouseEnter( config) {
        console.log(config, 'enter');
    }
    handleMouseLeave( config) {
        console.log(config, 'leave');
    }

    renderFixedContentItem({contentItemConfig, sectionConfig}) {
        let contentItemData = null;

        const contentSectionIndex = _.findIndex(this.props.state.body.body.sections, s => {
            return s.name === sectionConfig.name;
        });

        if (contentSectionIndex !== -1) {
            let contentItemIndex = _.findIndex(this.props.state.body.body.sections[contentSectionIndex].contents, c => c.title === contentItemConfig.name);
            if (contentItemIndex !== -1) {
                contentItemData = this.props.state.body.body.sections[contentSectionIndex].contents[contentItemIndex];
            }
        }

        let params = {
            handleChange: this.props.handleChange,
            data: contentItemData,
            config: contentItemConfig,
            sectionConfig: sectionConfig,
            contentTypes : this.props.state.contentTypes,
            fixed: true,
            content: this.props.content
        };

        return (
            <div >
                <ContentItem {...params} />
            </div>
        );

    }

    onSortEnd = ({sectionConfig, oldIndex, newIndex}) => {
        let sectionContentIndex = this.getSectionContentIndex(sectionConfig);

        const oldContent = [...this.props.state.body.body.sections[sectionContentIndex].contents].map((data, index) => ({
            ...data,
            oldIndex: index
        }))
            .filter(({fixed}) => fixed === false)
            .sort((a, b) => a.order - b.order);


        const newContents = arrayMoveImmutable(oldContent, oldIndex, newIndex)
            .map((data, index) => ({...data, order: index}));

        this.props.handleFreeContentOrder(sectionConfig, newContents);
    };

    renderFreeContent(sectionConfig) {

        let sectionContentIndex = this.getSectionContentIndex(sectionConfig);

        if (sectionContentIndex !== -1) {
            let freeContent = this.props.state.body.body.sections[sectionContentIndex].contents
                .map((data, index) => ({...data, contentIndex: index}))
                .filter(({fixed}) => fixed == false)
                .sort((a, b) => a.order - b.order);


            return freeContent.map((c, index) => {
                let params = {
                    handleChange: this.props.handleChange,
                    data: c,
                    config: c,
                    sectionConfig: sectionConfig,
                    fixed: false,
                    contentIndex: c.contentIndex,
                };


                return (
                    <SortableItem
                        key={index}
                        index={index}
                        free={true}
                        content={this.props.content}
                        params={params}
                        onFreeItemDelete={() => this.props.onFreeItemDelete(sectionConfig, index)}
                        handleFreeContentTitle={this.props.handleFreeContentTitle}
                        img={this.images[c.type]}
                        title={c.title}
                        type={c.type}
                        contentTypes={this.props.state.contentTypes}
                    />
                );

            });
        }

        return [];
    }

    getSectionContentIndex(sectionConfig) {
        return _.findIndex(this.props.state.body.body.sections, s => s.name === sectionConfig.name);
    }

    isSectionDisplayed(sectionConfig) {
        let sectionContentIndex = this.getSectionContentIndex(sectionConfig);

        return sectionContentIndex !== -1 ? this.props.state.body.body.sections[sectionContentIndex].displayed : true;
    }

    getLabelFromConfig(config) {
        if(config.hasOwnProperty('label')) {
            if(config.label === null) {
                return config.name;
            }

            if(config.label.length >=1) {
                return config.label;
            }
        }

        return config.name;
    }

    render() {
        return (
            <div class="editor">

                {this.props.state.contentType.sections.map((sectionConfig, index) => {

                    return (
                        <Dropdown
                            key={index}
                            type={'section'}
                            sectionId={index}
                            sectionConfig={sectionConfig}
                            visible={true}
                            displayed={this.isSectionDisplayed(sectionConfig)}
                            displayable={sectionConfig.displayable}
                            title={ this.getLabelFromConfig(sectionConfig) }
                            handleDisplayed={e => this.props.handleDisplayed(sectionConfig, e)}
                            onMouseEnter={() => this.props.onDropEnter(sectionConfig.name, null)}
                            onMouseLeave={() => this.props.onDropLeave(sectionConfig, null)}
                        >

                            <p class="editor-dropdown__title"><b>Fixed Block:</b> The layout of these items are set,
                                please change to content type only.</p>
                            {sectionConfig.fixed_content.map((contentItemConfig, fixedIndex) => {
                                return (

                                    <Dropdown
                                        key={fixedIndex}
                                        onMouseEnter={() => this.props.onDropEnter(sectionConfig.name, contentItemConfig.name)}
                                        onMouseLeave={() =>  this.props.onDropLeave(sectionConfig, contentItemConfig)}
                                        img={this.images[contentItemConfig.type]} title={this.getLabelFromConfig(contentItemConfig)}

                                        type={contentItemConfig.type}>

                                        {this.renderFixedContentItem({contentItemConfig, sectionConfig})}
                                    </Dropdown>
                                )
                            })}
                            <p className="editor-dropdown__title"><b>Free Content:</b> The content items are free, add
                                what you want</p>
                            {
                                sectionConfig.allowed_content.length > 0 && <FreeContentButtons
                                    addFreeContentItem={(type) => this.props.addFreeContentItem(index, sectionConfig, type)}
                                    allowedContent={sectionConfig.allowed_content} images={this.images}/>
                            }
                            <SortableContainer lockAxis="y"
                                               onSortEnd={e => this.onSortEnd({...e, sectionConfig, index})}
                                               useDragHandle>{this.renderFreeContent(sectionConfig)}
                            </SortableContainer>
                        </Dropdown>
                    )

                })}
            </div>
        )
    }
}
