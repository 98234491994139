import { useEffect } from 'react'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import XHRUpload from '@uppy/xhr-upload'

import '@uppy/image-editor/dist/style.css'

export default ({ onResourceSelect, fileTypes = null, endpoint = '', extraData }) => {
    useEffect(() => {
        console.log('loaded');

        let params = {}

        if (fileTypes != null && fileTypes.length > 0) {
            params.allowedFileTypes = fileTypes;
        }

        const uppy = new Uppy({
            restrictions: {
                maxNumberOfFiles: 1,
                ...params
            },
        });

        uppy.setMeta(extraData)

        uppy.use(Dashboard, {
            id: 'Dashboard',
            inline: true,
            target: '#uppy-upload',
            note: 'Supported: doc,docx,pdf,png,jpg,txt,xlsx,csv, 5mb or less',
            debug: true,
            autoProceed: false,
            metaFields: [
                { id: 'name', name: 'Name', placeholder: 'file name' },
                { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
            ],
        })

        uppy.use(XHRUpload, {
            endpoint:  endpoint,
            formData : true,
            headers : {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                'Accept': 'application/json',
            },
            extraData: { ...extraData }
        });

        uppy.on('upload-success', (file, response) => {
            onResourceSelect(response.body.data);
          })

        uppy.on('upload-error', (file, error, response) => {
            uppy.info(response.body.message);

        });

    }, []);

    return (
        <div class="images-upload">
            <div id="uppy-upload"></div>
        </div>
    )
}

