import { useState, useContext } from 'react';
import PopupContext from '../../../../../context/context-popup';

import Input from '../../../../../components/inputs/inputs-text';
import Checkbox from '../../../../../components/checkbox';

import Popup from '../../../../../components/popup';

import Row from '../../../../../utils/row';

import { ProceedButton, CancelButton, PublishButton } from '../../../../../components/buttons';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';
import LabelContainer from '../../../../../components/label-container';

import AlertManager from '../../../../../managers/alert/alert-manager';

export default function CourseModulePublish({ id, onSuccess }) {
    const { setPopup } = useContext(PopupContext);

    const clicked = () => {
        const published = new ELearningApi().publishCourse(id);

        if (published) {
            AlertManager.success('Successfully published the course');

            onSuccess();
        }
    }

    return (
        <Popup onClose={setPopup}>
            <div class="popup__inner">
                <h1>Publish a new version of this course</h1>
                <p>By publishing a new version of this course, any learners who have not completed the course will be notified to start again. If this is correct, please continue, otherwise, try ‘saving’ changes.</p>

                <div class="popup__buttons">
                    <PublishButton responsive={false} onClick={clicked}>Publish new version</PublishButton>
                    <CancelButton responsive={false} onClick={() => setPopup(null)}/>
                </div>
            </div>
        </Popup>
    )
}
