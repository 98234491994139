import { Component, createRef } from 'react';

import SidePopup from '../../../../../components/side-popup';
import SidePopupSection from '../../../../../components/side-popup-section';
import { SaveButton, CreateButton, DeleteButton } from '../../../../../components/buttons';

import Row from '../../../../../utils/row';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';
import Creatable from '../../../../../components/inputs/inputs-creatable';
import DropdownContent from '../../../../../components/dropdown-content/dropdown';

import { SmallPill } from '../../../../../components/pills';

import LabelContainer from '../../../../../components/label-container';

import SearchBarItem from '../../../../../components/search/search-bar-item';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';

import axios from 'axios';

import AlertManager from '../../../../../managers/alert/alert-manager';

import List from '../../../../../components/list/list';

import OverviewFeature from '../../course-edit-featured';

import CompaniesApi from '../../../../../lib/companies/companies-api';

import InputFileSelect from '../../../../../components/inputs/inputs-file-select';

import AdminAPI from '../../../../../lib/admin/admin-api';

import EmbedVideo from '../../embed-video';
import SelectInput from "@mui/material/Select/SelectInput";
import Dropdown from "react-dropdown";

import PopupContext from '../../../../../context/context-popup';

import { Editor } from '@tinymce/tinymce-react'
import contentApi from '../../../../../lib/content/content-api';

class Resources extends Component {
    constructor(props) {
        super(props);

        this.contentTypeIdResource = Number.parseInt(document.querySelector('meta[name="content-type-id-resources"]')?.content);
    }

    addResource() {
        const resources = Array.isArray(this.props.resurces) ? [ ...this.props.resources ] : [];

        resources.push({
            title: null,
            content_id: null,
            slug: null,
            description: null,
        });

        this.props.onOverviewChange({
            target: {
                name: 'resources',
                value: resources,
            }
        })
    }

    deleteResource(index) {
        let resources = [ ...this.props.resources ];

        resources = resources.filter((e, i) => i !== index);

        this.props.onOverviewChange({
            target: {
                name: 'resources',
                value: resources,
            }
        })
    }

    onResourceContentTypeAdd(index, data) {
        const resources = [ ...this.props.resources ];
        const resource = resources[index];

        resource.title = data?.label;
        resource.content_id = data?.value?.id;
        resource.slug = data?.value?.slug;
        resource.description = data?.value?.meta?.preview_description;

        this.props.onOverviewChange({
            target: {
                name: 'resources',
                value: resources,
            }
        })
    }

    onResourceContentTypeDelete(index) {
        const resources = [ ...this.props.resources ];
        const resource = resources[index];

        resource.content_id = null;

        this.props.onOverviewChange({
            target: {
                name: 'resources',
                value: resources,
            }
        })
    }

    async loadContent() {
        return axios.get(`/api/content-list`, {
            params: {
                content_type_ids: [ this.contentTypeIdResource ]
            }
        }).then((info) => {
            const data = info?.data?.data;

            this.setState({ content: data });

            const options = data
                .map((key) => ({
                    label: key.title,
                    value: key
                }));

            return options;
        });
    }

    render() {
        return (
            <SidePopupSection title="Course Resources">
                <LabelContainer label="These users will be responsible for managing learners on this course, including manual assessments if applicable. The Course Managers will be notified if the learner has any queries about this course.">
                    <List gap="20px">
                        { this.props.resources?.map((resource, index) => {
                            const head = () => {
                                return (
                                    <div className="utils__row utils__gap--20">
                                        <DeleteButton mobile={true} onClick={() => this.deleteResource(index)}/>
                                    </div>
                                )
                            }


                            return (
                                <DropdownContent
                                    title={resource?.title || `Resource ${index + 1}`}
                                    index={index}
                                    head={head()}
                                    className="grant-question-block"
                                >
                                    <div style={{ width: '100%', padding: '15px', boxSizing: 'border-box' }}>
                                        <List gap="20px">
                                            <LabelContainer label="Search the resources created in the Content system and once found, you can add this to your course. If you’ve not created the resource just yet, go to Content and create a new resource page.  ">
                                                <Select
                                                    defaultOptions
                                                    value={null}
                                                    key={resource?.content_id}
                                                    onChange={e => this.onResourceContentTypeAdd(index, e)}
                                                    loadOptions={this.loadContent.bind(this)}
                                                />

                                                { resource?.content_id && <Row gap="10px">
                                                    <div style={{
                                                        width: '100%',
                                                        borderRadius: '20px',
                                                        backgroundColor: '#0C3B99',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        color: 'white',
                                                        padding: '0px 20px'
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <path id="Path_128" data-name="Path 128" d="M20,21H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3H20a1,1,0,0,1,1,1V20A1,1,0,0,1,20,21ZM8,7V9h8V7Zm0,4v2h8V11Zm0,4v2h5V15Z" transform="translate(-3 -3)" fill="#fff"/>
                                                        </svg>
                                                        { resource?.title }
                                                    </div>
                                                    <DeleteButton onClick={() => this.onResourceContentTypeDelete(index)} mobile/>
                                                </Row> }
                                            </LabelContainer>
                                        </List>
                                    </div>
                                </DropdownContent>
                            )
                        })}

                        <CreateButton onClick={this.addResource.bind(this)}>Resource</CreateButton>
                    </List>
                </LabelContainer>
            </SidePopupSection>
        )
    }
}

export default class CourseEdit extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.ref = createRef(null);

        this.state = {
            course: this.props.course,
            courses: [],
            course_prerequisites: [],
            errors: [],
            eligibilityTags: [],
            comopanyProgrammes: []
        }
    }

    addEligibilityTag(tag) {
        const tags = this.state.course.company_programme_eligibility !== undefined
                    && this.state.course.company_programme_eligibility !== null ?
                    this.state.course.company_programme_eligibility : []

        tag = this.state.eligibilityTags.find(t => t.id === tag.value);

        if (tags.find(t => t.id === tag.id)) return;

        tags.push(tag);

        this.onChange({
            target: {
                name: 'company_programme_eligibility',
                value: tags
            }
        });
    }

    removeEligibilityTag(tag) {
        let tags = this.state.course.company_programme_eligibility !== undefined
                    && this.state.course.company_programme_eligibility !== null ?
                    this.state.course.company_programme_eligibility : []

        tags = tags.filter(t => t.id !== tag);

        this.onChange({
            target: {
                name: 'company_programme_eligibility',
                value: tags
            }
        });
    }

    save() {
        const course = { ...this.state.course }

        this.props.save(course).then(() => {
            this.props.onSuccess();
        }).catch(err => {
            this.setState({ errors: err })
        });
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>{ this.props.course.name }</p>
                </div>
                <SaveButton onClick={this.save.bind(this)}>Save</SaveButton>
            </div>
        );
    }

    onOverviewChange(e) {
        const { name, value } = e.target;

        const overview = this.state.course?.overview;

        this.onChange({
            target: {
                name: 'overview',
                value: {
                    ...overview,
                    [ name ]: value
                }
            }
        });
    }

    onChange = (e) => {
        const { name, value } = e.target;

        const course =  {
            ...this.state.course,
            [ name ]: value,
        }

        this.setState({
            course,
        });
    }

    async loadProgrammes() {
        return CompaniesApi.fetchProgrammes().then(data => {
            this.setState({ eligibilityTags: data });

            const options = data.map((key) => ({
                label: key.name,
                value: key.id
            }));

            return options;
        })
    }

    async loadCourses() {
        return new ELearningApi().fetchCourses().then(({ data }) => {
            this.setState({ courses: data });

            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))
                .filter(key => key.value !== this.state.course.id)
                .filter(key => !this.state.course?.course_prerequisites?.find(e => e.id === key.value))

            return options;
        })
    }

    onPreviewImageChange(e) {
        const { value } = e.target;

        this.onOverviewChange({
            target: {
                name: 'preview_image',
                value: value
            }
        })
    }

    addCourse(courseItem) {
        let course_prerequisites = this.state.course?.course_prerequisites !== undefined ? this.state.course?.course_prerequisites : [];

        courseItem = this.state.courses.find(e => e.id === courseItem.value);

        if (!this.state.course.hasOwnProperty('course_prerequisites')) {
            course_prerequisites = [ courseItem ]
        } else {
            const hasItemAlready = course_prerequisites?.find(i => i.id === courseItem.id);

            if (hasItemAlready) return;

            course_prerequisites.push(courseItem);
        }

        this.setState({ course_prerequisites: String(Math.random()) })

        this.onChange({
            target: {
                name: 'course_prerequisites',
                value: course_prerequisites
            }
        });
    }

    addTag(item) {
        let tags = this.state.course.e_learning_tags !== undefined && this.state.course.e_learning_tags !== null ? this.state.course.e_learning_tags : []

        item = this.state.tags.find(t => t.id === item.value);

        if (tags.find(t => t.id === item.id)) return;

        tags.push(item);

        this.onChange({
            target: {
                name: 'e_learning_tags',
                value: tags
            }
        });
    }

    onTagCreate(item) {
        new ELearningApi().createTag({
            name: item
        }).then(data => {
            this.setState({ tags: [...this.state.tags, data] }, () => {
                this.addTag({
                    label: data.name,
                    value: data.id
                });
            })
        });
    }

    removeTag(item) {
        let tags = this.state.course.e_learning_tags !== undefined && this.state.course.e_learning_tags !== null ? this.state.course.e_learning_tags : []

        tags = tags.filter(i => i.id !== item);

        this.setState({ tags: String(Math.random()) })

        this.onChange({
            target: {
                name: 'e_learning_tags',
                value: tags
            }
        });
    }

    addCourseManager(item) {
        let course_managers = this.state.course.course_managers !== undefined && this.state.course.course_managers !== null ? this.state.course.course_managers : []

        item = this.state.course_managers.find(t => t.id === item.value);

        if (course_managers.find(t => t.id === item.id)) return;

        course_managers.push(item);

        this.onChange({
            target: {
                name: 'course_managers',
                value: course_managers
            }
        });
    }

    removeCourseManager(item) {
        let course_managers = this.state.course?.course_managers !== undefined ? this.state.course?.course_managers : [];

        course_managers = course_managers.filter(i => i.id !== item);

        this.setState({ course_managers: String(Math.random()) })

        this.onChange({
            target: {
                name: 'course_managers',
                value: course_managers
            }
        });
    }

    async loadManagers() {
        return AdminAPI.getUsers().then(({ data }) => {
            this.setState({ course_managers: data });

            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))
                .filter(key => !this.state.course?.course_managers?.find(e => e.id === key.value))

            return options;
        })
    }

    async loadTags() {
        return new ELearningApi().getTags().then(({ data }) => {
            this.setState({ tags: data });

            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))
                .filter(key => !this.state.course?.e_learning_tags?.find(e => e.id === key.value))

            return options;
        })
    }

    onEstimatedTime(key, value) {
        let time = typeof this.state.course?.overview?.est_time === 'string' ? {} : this.state.course?.overview?.est_time;

        time[key] = value;

        this.onOverviewChange({
            target: {
                name: 'est_time',
                value: time,
            }
        })
    }

    onDifficulty(e) {
        const { value } = e;

        this.onChange({
            target: {
                name: 'difficulty',
                value: value,
            }
        });
    }

    removeCourse(courseItem) {
        let course_prerequisites = this.state.course?.course_prerequisites !== undefined ? this.state.course?.course_prerequisites : [];

        course_prerequisites = course_prerequisites.filter(i => i.id !== courseItem);

        this.setState({ course_prerequisites: String(Math.random()) })

        this.onChange({
            target: {
                name: 'course_prerequisites',
                value: course_prerequisites
            }
        });
    }

    render() {
        const { path } = this.props;

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.context.setPopup(null)} content={
                <>
                    <SidePopupSection title="General Details">
                        <Input errors={this.state.errors?.name} name="name" label="Name of course" placeholder="Name of course" value={this.state.course.name} onChange={this.onChange.bind(this)}/>
                        <Input errors={this.state.errors?.host_name} name="host_name" label="Name of host" placeholder="Name of host" value={this.state.course.host_name} onChange={this.onChange.bind(this)}/>
                        <LabelContainer label={'Visibility'} >
                                <Dropdown value={ this.state.course.private === 0 ? {label: 'Public', value: 0} : {value: 1, label: 'Private'}} onChange={(data) => this.onChange({ target : { name : 'private', value : data.value}})} options={[{ value : 0, label : 'Public'}, {value: 1, label: 'Private'}]} />
                        </LabelContainer>
                        <LabelContainer label="Course tags (tags will be used to filter through courses)">
                            <Creatable
                                defaultOptions
                                value={null}
                                key={this.state.course.e_learning_tags}
                                onChange={this.addTag.bind(this)}
                                loadOptions={this.loadTags.bind(this)}
                                onCreateOption={this.onTagCreate.bind(this)}
                            />
                            <Row wrap={true} gap="10px">
                            { this.state?.course?.e_learning_tags?.map((tag, index) => <SmallPill key={index} removable={true} onClick={() => this.removeTag(tag.id)}>{ tag.name }</SmallPill>)}
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Preview Image">
                        <InputFileSelect fill={true} value={this.state.course?.overview?.preview_image} onChange={this.onPreviewImageChange.bind(this)}/>
                    </SidePopupSection>

                    <SidePopupSection title="Trailer/Overview">
                        <List gap="20px">
                            <LabelContainer label="Description of Course">
                                <Editor
                                    onInit={(evt, editor) => this.ref.current = editor}
                                    value={this.state.course?.overview?.description}
                                    onEditorChange={(value, editor) => {
                                       this.onOverviewChange({
                                            target: {
                                                name: 'description',
                                                value: value
                                            }
                                        });
                                    }}
                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }
                                    }
                                />
                            </LabelContainer>

                            <OverviewFeature onChange={this.onOverviewChange.bind(this)} value={this.state.course.overview}/>
                        </List>
                    </SidePopupSection>

                    <SidePopupSection title="Difficulty">
                        <Dropdown value={this.state.course?.difficulty} options={['beginner', 'intermediate', 'advanced'].map(e => ({ label: e, value: e }))} onChange={this.onDifficulty.bind(this)}/>
                    </SidePopupSection>

                    <SidePopupSection title="Estimated Time">
                        <LabelContainer label="Estimated time to complete this course">
                            <Row fill>
                                <Input 
                                    label="Input Hours"
                                    mi={0}
                                    type="number"
                                    value={this.state.course?.overview?.est_time?.hours}
                                    onChange={e => {
                                        this.onEstimatedTime('hours', e.target.value);
                                    }}
                                />
    
                                <Input 
                                    label="Input Minutes (Max 60)"
                                    min={0}
                                    max={60}
                                    type="number"
                                    value={this.state.course?.overview?.est_time?.minutes}
                                    onChange={e => {
                                        this.onEstimatedTime('minutes', e.target.value);
                                    }}
                                />
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>


                    <SidePopupSection title="Course Managers">
                        <LabelContainer label="These users will be responsible for managing learners on this course, including manual assessments if applicable. The Course Managers will be notified if the learner has any queries about this course.">
                            <Select
                                defaultOptions
                                value={null}
                                key={this.state.course.course_managers}
                                onChange={this.addCourseManager.bind(this)}
                                loadOptions={this.loadManagers.bind(this)}
                            />
                            <Row wrap={true} gap="10px">
                                { this.state?.course?.course_managers?.map((tag, index) => <SmallPill key={index} removable={true} onClick={() => this.removeCourseManager(tag.id)}>{ tag.name }</SmallPill>)}
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Course Eligibility">
                        <LabelContainer label="Search the tag that a user must have assigned to their business in order to access this learning path. This course not be available on the website or to general myEB users, only to these who have this tag assigned to their business. ">
                            <Select
                                defaultOptions
                                value={null}
                                key={null}
                                onChange={this.addEligibilityTag.bind(this)}
                                loadOptions={this.loadProgrammes.bind(this)}
                            />
                            <Row wrap={true} gap="10px">
                                { this.state?.course?.company_programme_eligibility?.map((tag, index) => <SmallPill key={index} removable={true} onClick={() => this.removeEligibilityTag(tag.id)}>{ tag.name }</SmallPill>)}
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Courses required to gain access">
                        <Select
                            defaultOptions
                            value={null}
                            key={this.state.course_prerequisites}
                            onChange={this.addCourse.bind(this)}
                            loadOptions={this.loadCourses.bind(this)}
                        />
                        <Row wrap={true} gap="10px">
                            { this.state?.course?.course_prerequisites?.map((tag, index) => <SmallPill key={index} removable={true} onClick={() => this.removeCourse(tag.id)}>{ tag.name }</SmallPill>)}
                        </Row>
                    </SidePopupSection>

                    <Resources
                        onOverviewChange={this.onOverviewChange.bind(this)}
                        resources={this.state.course?.overview?.resources}
                    />
                </>
            }/>
        )
    }
}
