import { Component } from 'react';
import TextBox from './text-box';
import Image from './Image';
import { HtmlEditor, RichText, Gallery, TextInput, FetchedContent, Video, File } from './index';

const types = {
    'text-box': TextBox,
    'text-input' : TextInput,
    'image': Image,
    'html': HtmlEditor,
    'wysiwyg': RichText,
    'gallery' : Gallery,
    'fetched-content' : FetchedContent,
    'video': Video,
    'file': File,
}

class ContentItem extends Component {

    constructor(props) {
        super(props);
    }

    getType() {
        const { data, config, sectionConfig, contentIndex, handleChange, fixed, contentTypes, content } = this.props;
        const ComponentItemType = types[config.type] || types['text-box'];

        let params = {
            data: data ? data.body : '',
            meta: data ? data.meta : {},
            config : config,
            sectionConfig : sectionConfig,
            contentIndex : contentIndex,
            handleChange : handleChange,
            fixed: fixed,
            contentTypes : contentTypes,
            content,
        };

        return <ComponentItemType {...params}/>
    }

    render() {
        return this.getType();
    }
}

export default ContentItem;
