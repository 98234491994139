import React, { Component } from 'react'

const PopupContext = React.createContext()

class PopupProvider extends Component {
  // Context state
  state = {
      popup: null,
      popups: [],
      overlap: false,
  }

  // Method to update state
  setPopup = (popup, overlap = false) => {
    this.setState((prevState) => ({ popup, overlap }))
  }

  addPopup = (func) => {
      const popup = func(this.state.popups.length);
      const popups = [ ...this.state.popups, popup ]

      this.setState({ popups });
  }

  closePopup = (index = null) => {
      if (index !== null) {
          const findPopup = this.state.popups?.[index];

          if (findPopup) {
              const popups = this.state.popups.filter((p, i) => i !== index);

              this.setState({ popups })
          }

          return;
      }

     this.setPopup(null);
  }

  render() {
    const { children } = this.props
    const { popup, overlap } = this.state
    const { setPopup, closePopup, addPopup } = this;

    let popups = [
        popup,
    ];

    if (this.state.popups?.length > 0) {
        popups = [
            ...popups,
            ...this.state.popups,
        ]
    }

    return (
      <PopupContext.Provider
        value={{
          popup,
          setPopup,
          closePopup, addPopup,
          overlap,
        }}
      >
        { overlap === false && popup && popups.map(p => p) }
        { children }
      </PopupContext.Provider>
    )
  }
}

export default PopupContext

export { PopupProvider }
