import { useEffect } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { Editor } from '@tinymce/tinymce-react'

export default function QuestionTypeTextResponse({
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {

    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({
            target: {
                name: 'config',
                value: config
            }
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <Editor
                            value={question?.description || ''}
                            onEditorChange={(value, editor) => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: value,
                                    }
                                })
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }
                            }
                        />
                    </LabelContainer>
                </>
            } right={
                <>
                    <Row wrap={true}>
                        <List gap="20px">
                            <LabelContainer label="Input Field Size">
                                <Row wrap={true}>
                                    <Checkbox square={false} text="Single Line"
                                        onChange={e => {
                                            onConfigChange({
                                                target: {
                                                    name: 'input_field_size',
                                                    value: 'input'
                                                }
                                            })
                                        }}
                                        checked={question?.config?.input_field_size === 'input'}/>

                                    <Checkbox square={false} text="Multiple Line"
                                        onChange={e => {
                                            onConfigChange({
                                                target: {
                                                    name: 'input_field_size',
                                                    value: 'textarea',
                                                }
                                            })
                                        }}
                                        checked={question?.config?.input_field_size === 'textarea'}/>
                                </Row>
                            </LabelContainer>

                            { question.config?.word_count && <Input label="Minimum word count" name="minimum_word_count" value={question.config?.minimum_word_count} onChange={onConfigChange}/> }
                        </List>

                        <List gap="20px">
                            <LabelContainer label="Word Count">
                                <Checkbox text="Set a word count on this field"
                                    onChange={e => {
                                        onConfigChange({
                                            target: {
                                                name: 'word_count',
                                                value: !question?.config?.word_count,
                                            }
                                        })
                                    }}
                                    checked={question?.config?.word_count}/>
                            </LabelContainer>

                            { question.config?.word_count && <Input label="Maximum word count" name="maximum_word_count" value={question.config?.maximum_word_count} onChange={onConfigChange}/> }
                        </List>
                    </Row>

                    <LabelContainer label="Response Format">
                        <Row wrap={true}>
                            <Checkbox square={false} text="Plain Text"
                                onChange={e => {
                                    onConfigChange({
                                        target: {
                                            name: 'response_format',
                                            value: 'plain',
                                        }
                                    })
                                }}
                                checked={question?.config?.response_format === 'plain'}/>

                            <Checkbox square={false} text="Rich Text"
                                onChange={e => {
                                    onConfigChange({
                                        target: {
                                            name: 'response_format',
                                            value: 'rich',
                                        }
                                    })
                                }}
                                checked={question?.config?.response_format === 'rich'}/>
                        </Row>
                    </LabelContainer>

                    <Dropdown label="Next Question" value={getNextQuestion()} items={getMappedQuestions()} onChange={item => {
                        onConfigChange({
                            target: {
                                name: 'next_question',
                                value: item.value,
                            }
                        })
                    }}/>
                </>
            }/>
        </QuestionType>
    )
}
