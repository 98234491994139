import Dropdown from '../../../../../components/dropdown-content/dropdown';
import Input from '../../../../../components/inputs/inputs-text';
import Row from '../../../../../utils/row';

import List from '../../../../../components/list/list';

import QuestionTypes from './question/question-types';
import QuestionDropdown from './question/question-dropdown';

import {
    AddButton,
    DuplicateButton,
    DeleteButton,
} from '../../../../../components/buttons';

export default function CourseModuleDropdown({
    index,
    onQuestionClick,
    onQuestionRemove,
    onSectionDuplicate,
    onQuestionChange,
    onSectionEdit,
    section,
    removeSection,
    setPopup
}) {
    const head = () => {
        if (section.type === 'dynamic' || section.type === 'manual') return '';

        return (
            <div className="utils__row utils__gap--20">
                <DuplicateButton mobile={true} onClick={onSectionDuplicate}/>
                <DeleteButton mobile={true} onClick={removeSection}/>
            </div>
        )
    }

    let amountOfQuestions = section.questions?.length;

    return (
        <Dropdown
            title={`Section ${index + 1}: ${section?.title}`}
            index={index} head={head()}
            className="grant-question-block"
            open={section.type === 'dynamic' || section.type === 'manual'}
            hideClose={section.type === 'dynamic' || section.type === 'manual'}
            hideInfo={section.type === 'dynamic' || section.type === 'manual'}
        >
            { section.type !== 'dynamic' && section.type !== 'manual' &&
                <Dropdown.Header>
                    <Input label='Section Title' name="title" value={section?.title} onChange={onSectionEdit} fill={true}/>
                </Dropdown.Header>
            }

            <Dropdown.Content>
                <List gap="20px">
                    { section.questions.length > 0 &&
                        <List gap="10px" style={{
                            margin: '21px'
                        }}>
                            { section.questions.map((question, questionIndex) => {
                                amountOfQuestions--;

                                return (
                                    <QuestionDropdown
                                        style={{ zIndex: amountOfQuestions }}
                                        key={questionIndex}
                                        question={question}
                                        questions={section.questions}
                                        index={questionIndex}
                                        setPopup={setPopup}
                                        onQuestionChange={(e) => onQuestionChange(questionIndex, e)}
                                        onQuestionRemove={() => onQuestionRemove(questionIndex)}
                                    />
                                )
                            })}
                        </List>
                    }

                    <QuestionTypes onQuestionClick={onQuestionClick} type={section.type}/>
                </List>
            </Dropdown.Content>
        </Dropdown>
    )
}
