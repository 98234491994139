import Editor from '../../editor';
import EditorPreview from "./editor-preview";

export default ({
                    addFreeContentItem,
                    onFreeItemDelete,
                    state,
                    handleChange,
                    handleDisplayed,
                    handleFreeContentTitle,
                    handleFreeContentOrder,
                    onDropEnter,
                    onDropLeave,
                    selectedItem,
                    content
                }) => {
    return (
        <>
            <div class="content-editor__inner content-editor__editor">
                <p class="content-editor__title">Content Editor</p>
                <div class="content-editor__content">
                    <Editor
                        content={content}
                        onDropEnter={onDropEnter}
                        onDropLeave={onDropLeave}
                        state={state}
                        addFreeContentItem={addFreeContentItem}
                        onFreeItemDelete={onFreeItemDelete}
                        handleFreeContentOrder={handleFreeContentOrder}
                        handleFreeContentTitle={handleFreeContentTitle}
                        handleDisplayed={handleDisplayed}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div class="content-editor__inner content-editor__preview">
                <p class="content-editor__title">Web Page Preview</p>
                <div class="content-editor__content">
                    <EditorPreview
                        distributions={state.distributions}
                        contentVersion={state.body.body}
                        contentVersionId={state.body.id}
                        selected={selectedItem}
                        distributionMaps={state.distribution_maps}
                        published={state.content.published}
                        content={state.content}
                    />
                </div>
            </div>
        </>
    )
}
