import { useContext, useEffect, useRef } from 'react';
import { AddButton } from '../../../../../../components/buttons';

import Input from '../../../../../../components/inputs/inputs-text';

import QuestionType from "../../../../../../components/question/question-type-container";

import LabelContainer from '../../../../../../components/label-container';

import ImagesPopup from '../../../../../../components/popups/images/images-popup';

import File from '../../../../../../components/file/file';

import { Editor } from '@tinymce/tinymce-react';
import PopupContext from '../../../../../../context/context-popup';

export default function QuestionTypeGallery({ 
    question,
    index,
    onQuestionChange,
}) {
    const ref = useRef(null);
    const { setPopup } = useContext(PopupContext);

    const onAdd = () => {
        console.log(setPopup);

        setPopup(<ImagesPopup onClose={() => setPopup(null)}
            onResourceSelect={r => {
                setPopup(null);

                const files = [ ...question?.body ];

                files.push(r.resource);

                onQuestionChange({
                    target: {
                        name: 'body', 
                        value: files,
                    }
                });
            }}
            onlyShow={['browse', 'upload']}
        />);
    }

    const onRemove = (index) => {
        const files = [ ...question?.body ];

        files.splice(index, 1);

        onQuestionChange({
            target: {
                name: 'body', 
                value: files,
            }
        });
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <Input label="Video Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

            <LabelContainer label="Gallery Description" fill={true}>
                <Editor
                    onInit={(evt, editor) => ref.current = editor}
                    value={question?.description}
                    onEditorChange={(value, editor) => {
                        onQuestionChange({
                            target: {
                                name: 'description', 
                                value: value
                            }
                        });
                    }}
                    init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }
                    }
                />
            </LabelContainer>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px'
            }}>
                <AddButton onClick={onAdd}>Add Image</AddButton>
                { question.body !== null && Array.isArray(question.body) && question.body.map((file, index) => {
                    return (
                        <File key={index} file={file} onRemove={() => onRemove(index)}/>
                    )
                })}
            </div>
        </QuestionType>
    )
}