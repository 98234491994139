import React, {Component, useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { SeventyThirtyLayout } from "../../../../containers/layout";
import ActivityItem from '../../../../components/activity/activity-item';
import ResultsList from "../../../../components/results/results-list";

import { Empty } from '../../../../components/state';

import { CreateButton, SaveButton, DeleteButton, ProceedButton, AddButton } from "../../../../components/buttons";

import ContentContainer from "../../../../components/layout/content-container";

import ELearningDropdown from '../../components/e-learning-dropdown';

import ELearningApi from '../../../../lib/e-learning/e-learning-api';

import CourseCreate from '../../components/popups/course/course-add';
import CourseRemove from '../../components/popups/course/course-remove';

import LearningPathDetails from '../../components/popups/path/path-edit';
import LearningPathArchive from '../../components/popups/path/path-archive';
import LearningPathDelete from '../../components/popups/path/path-delete';

import AlertManager from '../../../../managers/alert/alert-manager';

import Sponsors from '../../../../components/sponsors';
import LabelContainer from '../../../../components/label-container';
import PopupContext, { PopupProvider } from '../../../../context/context-popup';
import PublishButton from '../../../../components/buttons/publish-button';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';

import {
    restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

class Activity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: new Array(0).fill({
                message: 'Test',
            }),
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <ContentContainer title={'Activity'} icon={<img src="/assets/content-container/activity.svg" />}>
                <ResultsList>
                    {
                        this.state.activity.map((item, index) =>{
                            return(
                                <ActivityItem item={item} key={index} />
                            );
                        })
                    }
                </ResultsList>
            </ContentContainer>
        );
    }
}

function Page(props) {
    const sortedCourses = useMemo(() => {
        return [...(props?.courses || [])]?.filter(({ order }) => order !== null)?.sort((a, b) => a.order - b.order);
    }, [props.courses]);

    const [links, setLinks] = useState(sortedCourses);
    const [activeId, setActiveId] = useState(null);

    useEffect(() => {
        if (sortedCourses?.length !== links?.length) {
            setLinks(sortedCourses);
        }
    }, [ sortedCourses ])

    const activeItem = useMemo(() => {
        return links[activeId - 1];
    }, [activeId, links]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragStart(event) {
        const { active } = event;
        setActiveId(active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        
        if (active && over && active.id !== over.id) {
            const oldIndex = active?.id - 1;
            const newIndex = over?.id - 1;

            setLinks(arrayMove(links, oldIndex, newIndex));
        }

        setActiveId(null);
    }

    useEffect(() => {
        props.onCoursesSort(links);
    }, [links]);

    if (!sortedCourses.length) return <Empty>No courses added to learning path</Empty>;

    return (
        <DndContext
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={links.map((course, index) => index + 1)}
                strategy={verticalListSortingStrategy}
            >
                <div className="e-learning-page__content">
                    {links.map((course, index) => {
                        const fetchedCourse = props?.page?.courses?.find(c => c.id === course?.course_id);
                        
                        return (
                            <ELearningDropdown 
                                key={course?.course_id}
                                index={index} 
                                course={fetchedCourse} 
                                removeCourse={props.removeCourse} 
                                duplicateCourse={props.duplicateCourse}
                            /> 
                        )
                    })}
                </div>
            </SortableContext>
        </DndContext>
    );
}

class LearningPath extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            page: null,
            popup: null,
            crumbs: [
                {
                    name: 'E-Learning',
                    link: '/e-learning'
                },
                {
                    name: 'Learning Paths',
                    link: '/e-learning/learning-paths'
                }
            ]
        }
    }

    componentDidMount() {
        this.fetchLearningPath();
    }

    async save() {
        const params = Object.assign(this.state.page, {
            managers: this.state.page.managers.map(({ id }) => id)
        });

        Promise.all([
            await new ELearningApi().updateLearningPath(this.props.id, params),
            await new ELearningApi().updateLearningPathCoursesOrder(this.props.id, {
                orders: this.state.page.learning_path_course_configs?.map((course, index) => {
                    return {
                        course_id: course?.course_id,
                        order: index,
                    }
                })
            })
        ]).then(() => {
            AlertManager.success('Sucessfully published learning path');

            this.fetchLearningPath();
        }).catch((error) => {
            AlertManager.error(error);
        })
    }

    async publish() {
        const params = Object.assign(this.state.page, {
            published: this.state.page?.published === null ? 1 : (this.state.page?.published === 1 ? 0 : this.state.page?.published === 0 ? 1 : null),
        });

        new ELearningApi().updateLearningPath(this.props.id, params).then(() => {
            AlertManager.success('Sucessfully published learning path');

            this.fetchLearningPath();
        }).catch((error) => {
            AlertManager.error(error);
        })
    }

    fetchLearningPath() {
        new ELearningApi().getLearningPath(this.props?.id).then(({ data }) => {
            this.setState({ page: data });
            this.forceUpdate()
        });
    }

    createCourse() {
        const { setPopup } = this.context;

        setPopup(
            <CourseCreate
                learningPathId={this.props?.id}
                learningPath={this.state.page}
                onSuccess={() => {
                    setPopup(null);
                    this.fetchLearningPath();
                }}
            />
        );
    }

    async duplicateCourse(course) {
        const courseCreate = await new ELearningApi().createCourse({ 
            name: course.name, 
            host_name: course.host_name, 
        });

        if (courseCreate) {
            const addCourseToPath = await new ELearningApi().addCourseToLearningPath(this.props.id, { 
                course_id: courseCreate.id 
            });

            if (addCourseToPath) {
                this.fetchLearningPath();
            }
        }
    }

    removeCourse(id) {
        const { setPopup } = this.context;

        setPopup(
            <CourseRemove
                id={id}
                learningPathId={this.props?.id}
                onSuccess={() => {
                    setPopup(null);
                    this.fetchLearningPath();
                }}
            />
        );
    }

    deletePath() {
        const { setPopup } = this.context;

        setPopup(
            <LearningPathDelete 
                path={this.props?.id} 
                onSuccess={() => window.location.reload()}
            />
        );
    }

    viewDetails() {
        const { setPopup } = this.context;

        setPopup(
            <LearningPathDetails 
                path={this.state.page} 
                fetchLearningPath={this.fetchLearningPath.bind(this)}
                onChange={this.onChange.bind(this)}
            />
        );
    }

    onChange(name, value) {
        this.setState({
            page: {
                ...this.state.page,
                [ name ] : value,
            }
        })
    }

    onCoursesSort(courses) {
        this.onChange('learning_path_course_configs', courses);
    }

    render() {
        const crumbs = [
            {
                name: 'E-Learning',
                link: '/e-learning'
            },
            {
                name: 'Learning Paths',
                link: '/e-learning/learning-paths'
            },
            {
                name: this.state.page?.name,
                link: ''
            }
        ]

        if (!this.state.page) return null;
        
        return (
            <PageLayout
                topLeft={<Breadcrumb crumbs={crumbs}/>}
                middle={
                    <div className='grant-editor__middle utils__gap--20'>
                        <div className="utils__rowSpaceBetween utils__gap--20">
                            <div className="utils__row utils__gap--20">
                                <CreateButton onClick={this.viewDetails.bind(this)}>Path Details</CreateButton>
                                <AddButton onClick={this.createCourse.bind(this)}>Add Course</AddButton>
                                <LabelContainer label="Visibility">
                                    <p>{ this.state.page.visible === 1 ? 'Visible' : 'Hidden'}</p>
                                </LabelContainer>
                            </div>

                            <div className="utils__row utils__gap--20">
                                <div className='grant-editor__published'>
                                    Published Version: ...<br/>
                                    Published: ...<br/>
                                </div>
                            </div>

                            <div className="utils__row utils__gap--20">                              
                                <PublishButton onClick={this.publish.bind(this)} toggle={this.state.page?.published}>{ !this.state.page?.published ? 'Publish' : 'Unpublish' }</PublishButton> 
                                <SaveButton mobile={true} onClick={this.save.bind(this)}/>
                                <DeleteButton onClick={this.deletePath.bind(this)}/>
                            </div>
                        </div>
                    </div>}>
                    <SeventyThirtyLayout
                        main={<Page page={this.state.page} 
                                    onCoursesSort={this.onCoursesSort.bind(this)}
                                    removeCourse={this.removeCourse.bind(this)}
                                    duplicateCourse={this.duplicateCourse.bind(this)}
                                    courses={this.state.page?.learning_path_course_configs}
                                    onChange={this.onChange.bind(this)}/>}
                        side={[
                            {
                                name: 'Activity',
                                component: <Activity/>
                            }
                        ]}
                    />
            </PageLayout>
        );
    }
}

const el = document.getElementById('learning-path');

if (el)
{
    ReactDOM.render(
        <PopupProvider>
            <LearningPath id={el.getAttribute('pageId')}/>
        </PopupProvider>    
    , el);
}
