import { Component, createRef } from 'react';

import SidePopup from '../../../../../components/side-popup';
import SidePopupSection from '../../../../../components/side-popup-section';
import { SaveButton } from '../../../../../components/buttons';

import Row from '../../../../../utils/row';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';
import Creatable from '../../../../../components/inputs/inputs-creatable';

import { SmallPill } from '../../../../../components/pills';

import LabelContainer from '../../../../../components/label-container';

import SearchBarItem from '../../../../../components/search/search-bar-item';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';

import AlertManager from '../../../../../managers/alert/alert-manager';

import List from '../../../../../components/list/list';

import OverviewFeature from '../../course-edit-featured';

import CompaniesApi from '../../../../../lib/companies/companies-api';

import InputFileSelect from '../../../../../components/inputs/inputs-file-select';

import AdminAPI from '../../../../../lib/admin/admin-api';

import EmbedVideo from '../../embed-video';
import SelectInput from "@mui/material/Select/SelectInput";
import Dropdown from "react-dropdown";

import PopupContext from '../../../../../context/context-popup';

import { Editor } from '@tinymce/tinymce-react'
import Container from '../../../../../containers/container';
import Divider from '../../../../../components/divider/divider';
import { stringToFriendlyDatetime } from '../../../../../lib/helpers/datetime-helpers';

import moment from 'moment';
import UAParser from 'ua-parser-js';

const icons = {
    'chrome': 'chrome.png',
    'firefox': 'firefox.png',
    'safari': 'safari.png',
    'edge': 'edge.png',
    'opera': 'opera.png',
}

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

export default class CourseWorkSessions extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            sessions: []
        }
    }

    componentDidMount() {
        new ELearningApi().getModuleSessions(this.props.courseWork.id, {
            module_id: this.props.module.id
        }).then(({ data }) => {
            this.setState({ sessions: data })
        })
    }

    head() {
        return (
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Module: { this.props.module.name }</p>
                </div>
            </div>
        )
    }

    render() {
        const { sessions, course } = this.props;

        const time = moment.duration(this.props.courseWork?.total_module_time_mins?.[this.props.module.id] || 0, 'minutes');

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.context.setPopup(null)} content={
                <>
                    <List gap="50px">
                        <LabelContainer label={<Container.Title color="#929BA8">Total module session time</Container.Title>}>
                            { time.hours() }h { time.minutes() }m
                        </LabelContainer>

                        { this.state.sessions?.map((session, index) => {
                            const duration = moment.duration(session?.duration_mins || 0, 'minutes');

                            const parser = new UAParser(session?.meta?.browser);

                            const result = parser.getResult();

                            return <List key={index} gap="0px">
                                <LabelContainer label={
                                    <Row gap="20px" alignItems='center'>
                                        <p style={{ whiteSpace: 'nowrap' }}>Session { this.state.sessions?.length - index }</p>
                                        <Divider top="0px" bottom="0px"/>
                                    </Row>
                                } color="#929BA8">
                                    <Container
                                        style={{
                                            padding: '20px',
                                            boxSizing: 'border-box',
                                            backgroundColor: 'white',
                                            borderRadius: '20px',
                                            border: '1px solid #C6C7D1'
                                        }}>
                                        <Container.Content>
                                            <List gap="10px" style={{ width: '100%' }}>
                                                <List gap="30px">
                                                    <Row wrap={true} gap="20px 50px" style={{flexGrow: '1'}}>
                                                        <Container style={{ minWidth: '184.66px', flex: '1 0 0'}}>
                                                            <Container.Header style={{ marginBottom: '10px' }}>
                                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Browser</Container.Title>
                                                            </Container.Header>
                                                            <Container.Content>
                                                            { result.browser?.name !== undefined ?
                                                                <img style={{ width: '25px', height: '25px' }} className={`browser-${index}`} src={`/assets/browser-icons/${icons[result.browser?.name.toLowerCase()]}`}/>
                                                                :
                                                                <Container.Text style={{ whiteSpace: 'nowrap' }} color="#354859">Not Recognisable</Container.Text>
                                                            }
                                                                <Tooltip
                                                                    anchorSelect={`.browser-${index}`}
                                                                    content={result.browser?.name}
                                                                />
                                                            </Container.Content>
                                                        </Container>

                                                        <Container style={{ minWidth: '184.66px', flex: '1 0 0'}}>
                                                            <Container.Header style={{ marginBottom: '10px' }}>
                                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Client IP</Container.Title>
                                                            </Container.Header>
                                                            <Container.Content>
                                                                <Container.Text style={{ whiteSpace: 'nowrap' }} color="#354859">{ session?.meta?.client_ip }</Container.Text>
                                                            </Container.Content>
                                                        </Container>

                                                        <Container style={{ minWidth: '184.66px', flex: '1 0 0'}}>
                                                            <Container.Header style={{ marginBottom: '10px' }}>
                                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Device</Container.Title>
                                                            </Container.Header>
                                                            <Container.Content>
                                                                <Container.Text style={{ whiteSpace: 'nowrap' }} color="#354859">{ result?.device?.vendor } { result?.device?.model }</Container.Text>
                                                            </Container.Content>
                                                        </Container>
                                                    </Row>

                                                    <Row wrap={true} gap="20px 50px" style={{flexGrow: '1'}}>
                                                        <Container style={{ minWidth: '184.66px', flex: '1 0 0'}}>
                                                            <Container.Header style={{ marginBottom: '10px' }}>
                                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Started</Container.Title>
                                                            </Container.Header>
                                                            <Container.Content>
                                                                <Container.Text  style={{ whiteSpace: 'nowrap' }} color="#354859">{ moment(session?.start).format('LLL') }</Container.Text>
                                                            </Container.Content>
                                                        </Container>

                                                        <Container style={{ minWidth: '184.66px', flex: '1 0 0'}}>
                                                            <Container.Header style={{ marginBottom: '10px' }}>
                                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Finished</Container.Title>
                                                            </Container.Header>
                                                            <Container.Content>
                                                                <Container.Text  style={{ whiteSpace: 'nowrap' }} color="#354859">{ moment(session?.end).format('LLL') }</Container.Text>
                                                            </Container.Content>
                                                        </Container>

                                                        <Container style={{ minWidth: '184.66px', flex: '1 0 0'}}>
                                                            <Container.Header style={{ marginBottom: '10px' }}>
                                                                <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Duration</Container.Title>
                                                            </Container.Header>
                                                            <Container.Content>
                                                                <Container.Text style={{ whiteSpace: 'nowrap' }} color="#354859">{ duration.hours() }h { duration.minutes() }m</Container.Text>
                                                            </Container.Content>
                                                        </Container>
                                                    </Row>
                                                </List>
                                            </List>
                                        </Container.Content>
                                    </Container>
                                </LabelContainer>
                            </List>
                        })}
                    </List>
                </>
            }/>
        )
    }
}
