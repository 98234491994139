import {Component} from 'react';
import FilePopup from '../popups/file/popup';
import MediaHelper from "../../lib/helpers/media";

import { UploadButton, CancelButton } from '../buttons';
import FileUploader from '../popups/images/file-uploader-popup';
import Row from '../../utils/row';
import LabelContainer from '../label-container';
import InputsText from '../inputs/inputs-text';
import Container from '../../containers/container';
import List from '../list/list';
import axios from 'axios';

const FileIcons = {
    'application/pdf': 'pdf',
    'application/msword': 'document-icon',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document-icon',
    'text/plain': 'txt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/csv': 'csv',
    'image/jpeg' : 'document-icon',
    'image/png' : 'document-icon',
    'pdf': 'pdf',
    'png': 'document-icon',
    'jpg': 'document-icon',
    'docx': 'document-icon',
    'doc': 'document-icon',
    'csv': 'csv'
}

export default class File extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popup: false,
            title: this.props.data,
            meta: {
                link: '',
                alt: '',
            }
        }
    }

    onChange(data, meta = {}) {
        const {config, sectionConfig, contentIndex, handleChange, fixed} = this.props;

        handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {
                body: data !== null ? data : this.props.data,
                meta: Object.assign({}, this.props.meta, meta)
            },
            fixed: fixed,
        });
    }

    clear() {
        const {config, sectionConfig, contentIndex, handleChange, fixed} = this.props;

        handleChange({
            title: config.name,
            contentItemConfig: config,
            sectionConfig,
            contentIndex,
            data: {
                body: null,
                meta: {}
            },
            fixed: fixed,
        });
    }

    handleSelect(r) {
        this.setState({popup: false});

        this.onChange(r);
    }

    render() {
        const {data, meta} = this.props;

        return (
            <>
                <FileUploader 
                    toggled={this.state.popup}
                    onClose={() => this.setState({ popup: false })}
                    onResourceSelect={this.handleSelect.bind(this)}
                    endpoint={`/media/content/file-upload`}
                    path="/api/admin/content-files"
                    searchPath="/api/admin/content-files/search"
                    deletePath="/api/admin/content-files"
                    showDelete={true}
                    fileTypes={[
                        '.docx',
                        '.doc',
                        '.pdf',
                        '.csv',
                        '.xlsx',
                        '.png',
                        '.jpg',
                        '.txt'
                    ]}
                    extraData={{
                        content_id: this.props.content?.id,
                    }}
                    
                />
                
                <div className="editor-image">
                    <div className={`editor-image__img ${!data ? 'editor-image__img--empty' : ''}`} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src={`/assets/file-types/${FileIcons[data?.mime_type]}.svg`} style={{ width: '100px', height: '100px' }}/>
                    </div>

                    <div class="editor-image__content">
                        <div class="editor-image__row">
                            <UploadButton onClick={() => this.setState({ popup: true })}>Upload/Change</UploadButton>
                            <CancelButton onClick={() => {
                                this.setState({popup: false});
                                this.clear();
                            }}>Cancel</CancelButton>
                        </div>

                        <List gap="40px">
                            <Container style={{flex: '1 0 0'}}>
                                <Container.Header>
                                    <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">File</Container.Title>
                                </Container.Header>
                                <Container.Content>
                                    <Container.Text fontSize="16px"><a href={ MediaHelper.formatPublicPath(`/${data?.path}`) }>{ MediaHelper.formatPublicPath(`/${data?.path}`) }</a></Container.Text>
                                </Container.Content>
                            </Container>

                            <Container style={{flex: '1 0 0'}}>
                                <Container.Header>
                                    <Container.Title style={{ whiteSpace: 'nowrap' }} color="#929BA8">Link Text</Container.Title>
                                </Container.Header>
                                <Container.Content>
                                    <InputsText value={meta.linktext ? meta.linktext : ''} onChange={e => this.onChange(null, { linktext: e.target.value })}/>
                                </Container.Content>
                            </Container>                      
                        </List>
                    </div>
                </div>
            </>
        )
    }
}
