import { useState, useEffect, useContext } from 'react';

import Container from "../../../../../containers/container";
import ContainerGrid from "../../../../../containers/container-grid";

import List from "../../../../../components/list/list";
import Question from "./question";

import Row from "../../../../../utils/row";
import Divider from "../../../../../components/divider/divider";
import LabelContainer from "../../../../../components/label-container";
import { EmptyButton, ProceedButton } from "../../../../../components/buttons";
import VideoContainer from '../../../../../components/video-container';
import Slider from '../../../../../components/inputs/inputs-slider';



import reactStringReplace from 'react-string-replace';

import MediaHelper from '../../../../../lib/helpers/media';

import BookingScreen from './booking-screen';
import BookedScreen from './booked-screen';
import NoSubmissionsScreen from './no-submissions-screen';

import Table from "../../../../../components/table/table";
import FileUpload from '../../../../../components/file-upload';

import ELearningApi from '../../../../../lib/e-learning/e-learning-api';

import { getExtension } from '../../../../../utils/mime-types';

import PopupContext from '../../../../../context/context-popup';

import CourseWorkSessions from '../../../components/popups/course-work/course-work-sessions';
import VideoTimeline from './video-timeline';

const states = {
    true: '/assets/e-learning/question-answer-correct.svg',
    false: '/assets/e-learning/question-answer-incorrect.svg',
}

export default function Module({ videoSessions, setManualState, manualState, fetchCourseWork, module, courseWork = {} }) {
    const { setPopup } = useContext(PopupContext);

    const sections = module?.sections || [];
    const moduleResponses = courseWork?.module_responses?.find(m => m.module_id === module.id) || [];
    const responses = moduleResponses?.question_responses || {};
    const moduleSubmission = courseWork?.course_module_submissions?.find(m => m.module_id === module.id && m.course_work_id === courseWork.id);

   const renderQuestionPassed = (question) => {
        const questionSubmission = moduleSubmission?.response?.[question.id];

        if (module.type === 'dynamic' && (questionSubmission?.passed !== null && questionSubmission?.passed !== undefined)) {
            const src = questionSubmission?.passed ? 'question-answer-correct' : 'question-answer-incorrect';

            return <img src={`/assets/e-learning/${src}.svg`}/>
        }

        return '';
    }

    const canShowManual = () => {
        const isBooked = courseWork?.manual_assessment_bookings?.find(m => m.module_id === module.id);

        if (isBooked) {
            return new Date(isBooked.date) < new Date();
        }

        return true;
    }

    const renderQuestionResponse = (question, response) => {
        switch(question.type) {
            case 'text-response':
                if (question.config?.response_format === 'rich') return <Container.Text html={response} style={{ width: 'inherit' }}></Container.Text>;

                return <Container.Text>{ response }</Container.Text>

            case 'single-choice':
                return <Container.Text>{ question.body?.[response]?.value }</Container.Text>

            case 'radio-buttons':
                return <Container.Text>{ response?.label }</Container.Text>

            case 'video':
                return <VideoTimeline question={question} videoSessions={videoSessions} courseWorkId={courseWork?.id} module={module}/>

            case 'multi-choice':
                return <List gap="20px">
                    { response?.map((key, index) => {
                        return <Row key={index} gap="20px" alignItems='center'>
                            <Container.Text>{ question.body?.[key]?.value }</Container.Text>
                            { <img src={states[question.correct_answers.includes(key)]}/> }
                        </Row>
                    })}
                </List>

            case 'sliding-scale':
                const marks = question.body?.length;

                const val = question.body?.findIndex(({ label }) => label === response?.label) || 0;

                return <Slider
                    marks
                    min={0}
                    max={marks}
                    disabled={true}
                    value={val + 1}
                    renderThumb={(props) => {
                        return <span { ...props }>{ response?.label }</span>
                    }}
                    renderMark={(props) => {
                        if (props.key < response) {
                            props.className = "customSlider-mark customSlider-mark-before";
                        } else if (props.key === response) {
                            props.className = "customSlider-mark customSlider-mark-active";
                        }

                        if (question.body === null) return '';

                        return (
                            <div { ...props }>
                                <p>{ question.body?.[props.key - 1]?.label || '‎' }</p>
                                <span className="customSlider-mark__inner">
                                    { props.key }
                                </span>
                            </div>
                        )
                    }}
                />

            case 'document-upload':
                return <Row gap="75px" wrap>
                    { response?.map(file => {
                        return <FileUpload
                            readOnly
                            onClick={() => {
                                window.open(`/e-learning/course-works/${courseWork.id}/file/${file.id}${getExtension(file.mime_type)}`, '_blank');
                            }}
                            value={{
                                file: file
                            }}
                        />
                    }) }
                </Row>

            case 'checkbox':
                return <List gap="20px">
                    { response?.map((obj, index) => {
                        return <Container.Text key={index}>{ obj?.label }</Container.Text>
                    })}
                </List>

            case 'list-ranking':
                return <List gap="20px">
                    { response?.map((value, index) =>
                        <Row key={index} gap="20px" alignItems='center'>
                            <Container.Text>{ value }</Container.Text>
                            { <img src={states[question.correct_answers[index] === value]}/> }
                        </Row>)
                    }
                </List>

            case 'text-matching':
                if (response === null || response === undefined) return '';

                const sorted = Object.keys(response).sort().reduce(
                    (obj, key) => {
                      obj[key.split('-')[1]] = response[key];
                      return obj;
                    },
                    {}
                );

                const aList = Object.keys(response)?.map(value => value?.split('-')[1])

                return <List gap="20px">
                    { aList?.map((key, index) => {
                        const word = Object.values(sorted)[index];
                        let questionAnswerBody = question.correct_answers?.[index]?.[key];

                        // If this is undefined its because list a has been randomised
                        if (questionAnswerBody === undefined) {
                            const correctAnswerBody = question.correct_answers?.find(answer => answer.hasOwnProperty(key));

                            if (correctAnswerBody) {
                                questionAnswerBody = correctAnswerBody?.[key];
                            }
                        }

                        const isCorrectAnswer = word === questionAnswerBody;

                        return (
                            <Row key={index} gap="20px" alignItems='center'>
                                <Container.Text>{ key } - { word }</Container.Text>
                                { <img src={states[isCorrectAnswer]}/> }
                            </Row>
                        )
                    })}
                </List>

            case 'fill-blank':
                let text = question.body;
                let body = question.body;

                text = reactStringReplace(text, /<\[(.*?)\]>/, (match, i) => {
                    const [ value, index ] = match.split('-');
                    const correct = question.correct_answers?.[index - 1] === response?.[match];

                    if (response?.[match] !== undefined) {
                        return <span style={{ textDecoration: 'underline', color: correct ? '#00C8C3' : '#ED2855' }}>
                            { response[match] }
                            { !correct ? ` (${question.correct_answers?.[index - 1]})` : '' }
                        </span>;
                    }

                    return match;
                });

                return <List gap="40px">
                    <Container.Text>{ text }</Container.Text>
                </List>;

            case 'multi-choice-image':
                return (
                    <ContainerGrid style={{ width: '100%' }}>
                        { response?.map(value => question.body[value]).map((image, index) => {
                            return (
                                <List key={index} gap="30px">
                                    <div
                                        onClick={() =>{}}
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                            cursor: 'pointer',
                                            borderRadius: '10px',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundImage: `url(${MediaHelper.formatPublicPath(`/images/${image?.value?.file?.name}`)})`,
                                            border: '1px solid black'
                                        }}></div>
                                        <Row gap="20px" alignItems='center'>
                                            <Container.Text color="#61707F" fontSize="14px">{ image?.label }</Container.Text>
                                            { <img src={states[response.includes(index)]}/> }
                                        </Row>
                                </List>
                            )
                        })}
                    </ContainerGrid>
                )

            case 'table':
                let columns = question?.body || [];

                columns = columns.map(({ value }) => ({
                    title: value
                }));

                return <Table columns={columns}
                    onChange={() => {}}
                    value={response}
                    readOnly={true}
                    allowNewRows={question?.config?.allow_new_rows}
                    defaultRowCount={question?.config?.default_row_count}
                    maxRowCount={question?.config?.maximum_row_count}/>

            default:
                return <Container.Text>{ response }</Container.Text>
        }
    }

    if (courseWork?.module_responses === null && videoSessions?.length === 0) {
        return <NoSubmissionsScreen/>
    }

    if (module.type === 'manual') {
        if (courseWork?.module_requiring_assessment_booking === module.id) return <BookingScreen fetchCourseWork={fetchCourseWork} courseWork={courseWork} module={module}/>
        if (!canShowManual()) return <BookedScreen module={module} booking={courseWork?.manual_assessment_bookings?.find(m => m.module_id === module.id)}/>
    }

    const renderStatus = () => {
        let status = <Container.Text color="#FF7612">Awaiting Submission</Container.Text>;

        if (moduleSubmission) {
            if (moduleSubmission.passed === 1) {
                status = <Container.Text color="#00C8C3">Passed</Container.Text>;
            }

            if (moduleSubmission.passed === 0) {
                status = <Container.Text color="#ED2855">Failed</Container.Text>;
            }

            if (moduleSubmission.passed === null) {
                status = <Container.Text color="#FF7612">Waiting to be marked</Container.Text>;
            }
        }

        return status;
    }

    const sessionPopup = () => {
        setPopup(<CourseWorkSessions module={module} courseWork={courseWork}/>);
    }

    const notToRender = [
        'image',
        'gallery',
        'html',
        'rich-text'
    ]

    return (
        <List gap="80px">
            <Row justifyContent='space-between'>
                <LabelContainer label="Title">
                    { module?.name }
                </LabelContainer>
                <Row gap="40px">
                    <Row gap="50px">
                        <LabelContainer label="Module Type">
                            { module.type }
                        </LabelContainer>

                        <LabelContainer label="Session Time">
                            { String(courseWork.total_module_time_mins[module.id]) }
                        </LabelContainer>

                        <LabelContainer label="Status">
                            { renderStatus() }
                        </LabelContainer>

                        <ProceedButton onClick={sessionPopup}>Course module Session</ProceedButton>
                    </Row>
                </Row>
            </Row>

            <List gap="60px">
                { sections.map((section, index) => {
                    return (
                        <Container key={index} gap="20px">
                            <Container.Header>
                                <Row center={true} gap="15px">
                                    <Container.Icon src="/assets/sections/section-information.svg"/>
                                    <Container.Title fontSize="18px" color="#354859">Section { index + 1 }: { section?.title } </Container.Title>
                                </Row>
                            </Container.Header>
                            <Container.Content>
                                <List gap="100px">
                                    { section?.questions?.map((question , index) => {
                                        const response = responses?.[question.id];
                                        const passed = manualState.question_markings.find(m => m.id === question.id)?.passed;
                                        const moduleResponse = courseWork.course_module_submissions.find(m => m.module_id === module.id)?.response?.[question.id];

                                        if (notToRender.includes(question.type)) return '';

                                        return (
                                            <Question key={index} title={
                                                <Row gap="20px" alignItems='center'>
                                                    <Container.Text>{index + 1}. {question?.title}</Container.Text>
                                                    { renderQuestionPassed(question) }
                                                </Row>
                                            } description={question?.description}>
                                                <Row justifyContent="space-between" fill>
                                                    { renderQuestionResponse(question, response) }

                                                    { module.type !== 'dynamic' && module.type !== 'information' &&
                                                        <Row gap="20px">
                                                            <EmptyButton className={passed === true || moduleResponse?.passed === true ? 'button--green' : ''} onClick={() => {
                                                                if ((moduleResponse === null || moduleResponse === undefined) && moduleSubmission !== undefined) {
                                                                    setManualState('question_markings', {
                                                                        id: question.id,
                                                                        passed: true,
                                                                    })
                                                                }
                                                            }}>Pass</EmptyButton>

                                                            <EmptyButton className={passed === false || moduleResponse?.passed === false ? 'button--delete' : ''} onClick={() => {
                                                                if ((moduleResponse === null || moduleResponse === undefined) && moduleSubmission !== undefined) {
                                                                    setManualState('question_markings', {
                                                                        id: question.id,
                                                                        passed: false,
                                                                    })
                                                                }
                                                            }}>Fail</EmptyButton>
                                                        </Row>
                                                    }
                                                </Row>
                                            </Question>
                                        )
                                    })}
                                </List>
                            </Container.Content>

                        </Container>
                    )
                })}
            </List>
        </List>
    )
}
