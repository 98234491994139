import { Component, createRef } from 'react';

import SidePopup from '../../../../../components/side-popup';
import SidePopupSection from '../../../../../components/side-popup-section';
import { SaveButton } from '../../../../../components/buttons';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';
import Switch from '../../../../../components/inputs/inputs-switch';
import Creatable from '../../../../../components/inputs/inputs-creatable';

import Row from '../../../../../utils/row';

import LabelContainer from '../../../../../components/label-container';

import SearchBarItem from '../../../../../components/search/search-bar-item';

import AdminApi from '../../../../../lib/admin/admin-api';
import ELearningAPI from '../../../../../lib/e-learning/e-learning-api';

import { SmallPill } from '../../../../../components/pills';

import AlertManager from '../../../../../managers/alert/alert-manager';
import PopupContext from '../../../../../context/context-popup';
import CompaniesApi from '../../../../../lib/companies/companies-api';

import { Editor } from '@tinymce/tinymce-react';

export default class ELearningView extends Component {
    static contextType = PopupContext;
    
    constructor(props) {
        super(props);

        this.ref = createRef(null);

        this.state = {
            users: [],
            path: this.props.path,
            tags: [],
            eligibilityTags: [],
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    async getUsers() {
        const { data } = await AdminApi.getUsers();

        this.setState({ 
            users: data
        });
    }

    addManager(manager) {
        manager = this.state.users.find(user => user.id === manager.value);

        this.onChange('managers', [ ...this.state.path.managers, manager ]);
    }

    deleteManager(manager) {
        let managers = this.state.path.managers;

        managers = managers.filter(m => m.id !== manager.id);

        this.onChange('managers', managers);
    }

    save() {
        const params = Object.assign(this.state.path, {
            managers: this.state.path.managers.map(({ id }) => id),
            e_learning_tag_ids: this.state.path.e_learning_tags.map(({ id }) => id),
        })

        Promise.all([
            new ELearningAPI().updateLearningPath(this.props.path?.id, params),
            new ELearningAPI().setLearningPathCompanyProgrammeEligibility(this.props.path?.id, {
                company_programme_ids: this.state.path?.company_programme_eligibility.map(({ id }) => id),
            })
        ]).then(() => {
            AlertManager.success('Sucessfully saved your learning path');

            this.props.fetchLearningPath();
            this.context.setPopup(null);
        }).catch((error) => {
            AlertManager.error(error);
        })
    }

    async loadProgrammes() {
        return CompaniesApi.fetchProgrammes().then(data => {
            this.setState({ eligibilityTags: data });

            const options = data.map((key) => ({
                label: key.name,
                value: key.id
            }));

            return options;
        })
    }

    addEligibilityTag(tag) {
        const tags = this.state.path.company_programme_eligibility !== undefined
                    && this.state.path.company_programme_eligibility !== null ?
                    this.state.path.company_programme_eligibility : []

        tag = this.state.eligibilityTags.find(t => t.id === tag.value);

        if (tags.find(t => t.id === tag.id)) return;

        tags.push(tag);

        this.onChange('company_programme_eligibility', tags);
    }

    removeEligibilityTag(tag) {
        let tags = this.state.path.company_programme_eligibility !== undefined
                    && this.state.path.company_programme_eligibility !== null ?
                    this.state.path.company_programme_eligibility : []

        tags = tags.filter(t => t.id !== tag);

        this.onChange('company_programme_eligibility', tags);
    }

    async loadTags() {
        return new ELearningAPI().getTags().then(({ data }) => {
            this.setState({ tags: data });

            const options = data
                .map((key) => ({
                    label: key.name,
                    value: key.id
                }))
                .filter(key => !this.state.path?.e_learning_tags?.find(e => e.id === key.value))

            return options;
        })
    }

    filter(inputValue) {
        return this.state.users
        .filter(user => 
            !this.state.path.managers.find(manager => manager.id === user.id)
        )
        .filter((i) =>
            i.name.toLowerCase().includes(inputValue.toLowerCase()) 
        ).map(user => ({
            value: user.id,
            label: user.name,
        }));
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>{ this.props.path?.name }</p>
                </div>
                <SaveButton onClick={this.save.bind(this)}>Save</SaveButton>
            </div>
        );
    }

    onChange(name, value) {
        this.setState({
            path: {
                ...this.state.path,
                [ name ]: value,
            }
        });
    }

    onOverviewChange(e) {
        const { name, value } = e.target;

        const overview = this.state.path?.overview;

        this.onChange('overview', {
            ...overview,
            [ name ]: value
        });
    }

    onEstimatedTime(key, value) {
        let time = typeof this.state.path?.overview?.est_time === 'string' ? {} : this.state.path?.overview?.est_time;

        time[key] = value;

        this.onOverviewChange({
            target: {
                name: 'est_time',
                value: time,
            }
        })
    }
    
    addTag(item) {
        let tags = this.state.path.e_learning_tags !== undefined && this.state.path.e_learning_tags !== null ? this.state.path.e_learning_tags : []

        item = this.state.tags.find(t => t.id === item.value);

        if (tags.find(t => t.id === item.id)) return;

        tags.push(item);

        this.onChange('e_learning_tags', tags);
    }

    onTagCreate(item) {
        new ELearningAPI().createTag({
            name: item
        }).then(data => {
            this.setState({ tags: [...this.state.tags, data] }, () => {
                this.addTag({
                    label: data.name,
                    value: data.id
                });
            })
        });
    }

    removeTag(item) {
        let tags = this.state.path.e_learning_tags !== undefined && this.state.path.e_learning_tags !== null ? this.state.path.e_learning_tags : []

        tags = tags.filter(i => i.id !== item);

        this.onChange('e_learning_tags', tags);
    }

    render() {
        console.log(this.state.path?.company_programme_eligibility)
        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.context.setPopup(null)} content={
                <>
                    <SidePopupSection title="General Details">
                        <Input label="Name of learning path" placeholder="Name of learning path" value={ this.state.path.name } 
                            onChange={e => this.onChange('name', e.target.value)}/>

                        <LabelContainer label="Description of learning path">
                            <Editor
                                onInit={(evt, editor) => this.ref.current = editor}
                                value={this.state.path?.description}
                                onEditorChange={(value, editor) => {
                                    this.onChange('description', value);
                                }}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }
                                }
                            />
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Estimated Time">
                        <LabelContainer label="Estimated time to complete this learning path">
                            <Row fill>
                                <Input 
                                    label="Input Hours"
                                    mi={0}
                                    type="number"
                                    value={this.state.path?.overview?.est_time?.hours}
                                    onChange={e => {
                                        this.onEstimatedTime('hours', e.target.value);
                                    }}
                                />
    
                                <Input 
                                    label="Input Minutes (Max 60)"
                                    min={0}
                                    max={60}
                                    type="number"
                                    value={this.state.path?.overview?.est_time?.minutes}
                                    onChange={e => {
                                        this.onEstimatedTime('minutes', e.target.value);
                                    }}
                                />
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Learning Path Managers">
                        <LabelContainer label="These users will be responsible for managing courses on this learning path. The users will be selectable per course to be assigned to assessments and activity updaters for each course.">
                            <Select value={null} key={this.state.path.managers} filter={this.filter.bind(this)} onChange={this.addManager.bind(this)}/>
                            <Row wrap={true} gap="10px">
                                { this.state.path.managers.map((manager, index) => {
                                    return <SmallPill key={index} removable={true} onClick={() => this.deleteManager(manager)}>{ manager.name }</SmallPill>
                                })}
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Learning Path Eligibility">
                        <LabelContainer label="Search the tag that a user must have assigned to their business in order to access this learning path. This path will not be available on the website or to general myEB users, only to these who have this tag assigned to their business. ">
                            <Select
                                defaultOptions
                                value={null}
                                key={null}
                                onChange={this.addEligibilityTag.bind(this)}
                                loadOptions={this.loadProgrammes.bind(this)}
                            />
                            <Row wrap={true} gap="10px">
                                { this.state?.path?.company_programme_eligibility?.map((tag, index) => <SmallPill key={index} removable={true} onClick={() => this.removeEligibilityTag(tag.id)}>{ tag.name }</SmallPill>)}
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Learning Path Tags">
                        <LabelContainer label="Tags (tags will be used to filter through learning paths)">
                            <Creatable
                                defaultOptions
                                value={null}
                                key={this.state.path.e_learning_tags}
                                onChange={this.addTag.bind(this)}
                                loadOptions={this.loadTags.bind(this)}
                                onCreateOption={this.onTagCreate.bind(this)}
                            />
                            <Row wrap={true} gap="10px">
                                { this.state?.path?.e_learning_tags?.map((tag, index) => <SmallPill key={index} removable={true} onClick={() => this.removeTag(tag.id)}>{ tag.name }</SmallPill>)}
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Visibility">
                        <Switch checked={this.state.path?.visible === 1} onChange={(e) => this.onChange('visible', e ? 1 : 0)} label="Select whether the courses on this learning path are Visible or Hidden. If this changed at a later stage, all learners on the course prior the change will continue to have access."/>
                    </SidePopupSection>
                </>
            }/>
        )
    }
}
