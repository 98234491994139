import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { MinMaxLayout, SeventyThirtyLayout } from "../../../../containers/layout";


import ContentContainer from "../../../../components/layout/content-container";
import Container from '../../../../containers/container';

import { CreateButton } from '../../../../components/buttons';

import Sponsors from '../../../../components/sponsors';
import StatesEmpty from "../../../../components/state/state-empty";
import Navigation from '../../../grants/components/grants-application-navigation';

import SearchBarItem from '../../../../components/search/search-bar-item';

import ELearningPaths from '../../components/e-learning-path-list';
import ELearningView from '../../components/popups/e-learning-view';
import ELearningCreate from '../../components/popups/e-learning-create';
import ELearningAPI from '../../../../lib/e-learning/e-learning-api';
import ELearningFilters from '../../components/e-learning-path-filters';

import ResultsList from "../../../../components/results/results-list";
import ResultsListItem from "../../../../components/results/results-list-item";
import ResultsListItemMeta from '../../../../components/results/results-list-item-meta';
import ResultListItemDetails from "../../../../components/results/results-list-item-details";
import { ResultListItemMetaInfo } from '../../../../components/results/result-list-item-meta-info';

import NavButton from '../../../../components/nav-button/nav-button';

import { stringToFriendlyDatetime, datetimeLocalToString } from '../../../../lib/helpers/datetime-helpers';
import List from '../../../../components/list/list';
import { CustomButton } from '../../../../components/buttons';
import Row from '../../../../utils/row';
import AppointmentsCalendar from '../../../../components/appointments/appointments-calendar';

import dayjs from 'dayjs';
import LabelContainer from '../../../../components/label-container';

import ButtonNext from '../../../../components/buttons-navigation/button-next';
import ActivityItem from '../../../../components/activity/activity-item';

const crumbs = [
    {
        name: 'E-Learning',
        link: '/e-learning'
    }
]

class CourseWorks extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            manualAssessmentBookings: [],
            savedDate: new Date(),
            courses: [],
            currentPage: 1,
        };
    }

    componentDidMount() {
        this.fetch();

        let date = new Date();
        date.setHours(0, 0, 0, 0);

        this.onManualAssessmentChange(date);
    }

    async fetch() {
        this.fetchCourses();
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.fetch());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.meta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    fetchCourses() {
        new ELearningAPI().fetchCourses({
            per_page: 10,
            page: this.state.currentPage,
        }).then(({ data, meta }) => {
            this.setState({ courses: data, meta });
        });
    }

    onManualAssessmentChange(date) {
        let date2 = new Date(date);
        date2.setHours(23, 59, 0, 0);

        date = dayjs(date);
        date2 = dayjs(date2);

        this.setState({ savedDate: date });


        new ELearningAPI().getManualAssessments({
            from: datetimeLocalToString(date),
            to: datetimeLocalToString(date2),
        }).then(({ data }) => {
            this.setState({ manualAssessmentBookings: data });
        });
    }

    renderAppointments() {
        if (this.state.manualAssessmentBookings?.length === 0) return <StatesEmpty
            style={{ width: '100%' }}
            title="Manual Assessment Bookings"
            description="No assessment bookings for this day"
        />

        return (
            <List gap="20px" style={{ width: '100%' }}>
                { this.state.manualAssessmentBookings?.map((assessment, index) => {
                    const module = assessment?.course?.latest_course_versions?.[0]?.modules?.find(m => m.id === assessment.module_id);

                    return (
                        <ResultsListItem
                            key={index}
                            icon={<img src="/assets/e-learning/course.svg"/>}
                            details={<ResultListItemDetails title={assessment?.course?.name} subtitle={module?.name}/>}
                            metaOne={<ResultsListItemMeta data={[stringToFriendlyDatetime(assessment?.date)]}/>}
                            metaTwo={<ResultsListItemMeta data={[assessment?.learner?.name]} />}
                            actions={<NavButton mobile={true} onClick={() => window.location.href = `/e-learning/course-works/${assessment.course_work_id}?module=${module.id}`}/>}
                        />
                    )
                })}
            </List>
        )
    }

    renderRecentlyUpdatedCourses() {
        if (this.state.courses?.length === 0) return <StatesEmpty
            title={"Recently Updated Courses"}
            description={'There seems to be no updated courses'}
        />

        return (
            <ResultsList
                titleOne={'Course Name'}
                titleTwo={''}
                titleThree={''}
            >
                { this.state.courses?.map((course, index) => {
                    return (
                        <ResultsListItem
                            key={index}
                            icon={<img src="/assets/e-learning/course.svg"/>}
                            details={<ResultListItemDetails title={course?.name} subtitle={''}/>}
                            metaOne={<ResultsListItemMeta data={[`Last Edited: ${stringToFriendlyDatetime(course.updated_at)}`]}/>}
                            metaTwo={<ResultsListItemMeta data={['']} />}
                            actions={<NavButton mobile={true} onClick={() => window.location.href = `/e-learning/courses/${course.id}`}/>}
                        />
                    )
                })}
            </ResultsList>
        )
    }

    render() {

        return (
            <List gap="40px">
                <Row gap="20px">
                    <CustomButton
                        icon={<img style={{ filter: 'invert(100%)' }} src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}
                        className='button--create'
                        onClick={() => window.location.href = '/e-learning/learning-paths'}>Learning Paths</CustomButton>

                    <CustomButton
                        icon={<img style={{ filter: 'invert(100%)' }} src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}
                        className='button--create'
                        onClick={() => window.location.href = '/e-learning/learners'}>Learners</CustomButton>
                </Row>

                <ContentContainer
                    title={'Manual Assessment Bookings'}
                    count={null}
                    icon={<img src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}>
                    <Row gap='20px'>
                        <AppointmentsCalendar onChange={this.onManualAssessmentChange.bind(this)}/>

                        { this.renderAppointments() }
                    </Row>
                </ContentContainer>

                <ContentContainer
                    title={'Recently Updated Courses'}
                    count={null}
                    icon={<img src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}
                    bottom={
                        <Navigation
                            back={this.goBack.bind(this)}
                            next={this.goNext.bind(this)}
                            setPage={this.setPage.bind(this)}
                            meta={this.state.meta}
                            backDisabled={this.state.currentPage == 1}
                            nextDisabled={this.state.currentPage == this.state.meta?.last_page}
                        />
                    }
                >
                    { this.renderRecentlyUpdatedCourses() }
                </ContentContainer>

            </List>

        );
    }
}

class AssessmentsNeedingBooking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: []
        };
    }

    componentDidMount() {
        new ELearningAPI().getAllCourseWork({
            requiring_assessment_booking: 1
        }).then(({ data }) => {
            this.setState({
                activity: data.map(d => ({
                    message: d.course?.name,
                    date: d.updated_at,
                    name: `${d?.user?.name}`,
                    module: d?.course_version?.modules.find(m => m.id === d.module_requiring_assessment_booking),
                    course_work_id: d.id,
                }))
            })
        });
    }

    render() {
        return (
            <ContentContainer title={'Assessments needing booking'} icon={<img src="/assets/content-container/activity.svg" />}>
                { this.state.activity.length > 0 ?
                    <ResultsList>
                    {
                        this.state.activity.map((item, index) =>{
                            return (
                                <ResultsListItem
                                    key={index}
                                    details={<ResultListItemDetails title={item?.message} subtitle={item?.module?.name} />}
                                    metaOne={
                                        <ResultListItemMetaInfo
                                            itemOne={<p>{ stringToFriendlyDatetime(item?.date) }</p>}
                                            itemTwo={<p>{ item?.name }</p>}
                                        />
                                    }
                                    actions={<NavButton mobile={true} onClick={() => window.location.href = `/e-learning/course-works/${item?.course_work_id}?module=${item?.module?.id}`}/>}
                                />
                            );
                        })
                    }
                </ResultsList>    :
                <Row justifyContent='center'>
                    <p>Nothing to show...</p>
                </Row>
                }
            </ContentContainer>
        );
    }
}

class AssessmentsAwaitingReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: []
        };
    }

    componentDidMount() {
        new ELearningAPI().getAllCourseWork({
            requiring_assessment: 1
        }).then(({ data }) => {
            this.setState({
                activity: data.map(d => ({
                    message: d.course?.name,
                    date: d.updated_at,
                    name: `${d?.user?.name}`,
                    module: d?.course_version?.modules.find(m => m.id === d.module_requiring_assessment_booking),
                    course_work_id: d.id,
                }))
            })
        });
    }

    render() {
        return (
            <ContentContainer title={'Assessments awaiting review'} icon={<img src="/assets/content-container/activity.svg" />}>
                { this.state.activity.length > 0 ?
                    <ResultsList>
                    {
                        this.state.activity.map((item, index) =>{
                            return (
                                <ResultsListItem
                                    key={index}
                                    details={<ResultListItemDetails title={item?.message} subtitle={item?.module?.name} />}
                                    metaOne={
                                        <ResultListItemMetaInfo
                                            itemOne={<p>{ stringToFriendlyDatetime(item?.date) }</p>}
                                            itemTwo={<p>{ item?.name }</p>}
                                        />
                                    }
                                    actions={<NavButton mobile={true} onClick={() => window.location.href = `/e-learning/course-works/${item?.course_work_id}?module=${item?.module?.id}`}/>}
                                />
                            );
                        })
                    }
                </ResultsList>    :
                <Row justifyContent='center'>
                    <p>Nothing to show...</p>
                </Row>
                }
            </ContentContainer>
        );
    }
}

const App = () =>
{

    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}>
            <div className={'e-learning-index'}>
                <SeventyThirtyLayout
                    main={<CourseWorks/>}
                    side={[
                        {
                            name: 'Assessments needing booking',
                            component: <AssessmentsNeedingBooking/>
                        },
                        {
                            name: 'Assessments awaiting review',
                            component: <AssessmentsAwaitingReview/>
                        }
                    ]}
                />
            </div>
        </PageLayout>

    );
}

const el = document.getElementById('e-learning-dashboard');

if (el)
{
    ReactDOM.render(<App/>, el);
}
