export default function StatesEmpty(props) {
    const {
        title = null,
        description = null,
        button = null,
        children = null,
        className= '',
        style = {}
    } = props;

    return (
        <div className={`states-empty ${className}`} style={style}>
            <div className="states-empty__head">
                { title && <h1 className="states-empty__title">{ title }</h1> }
                { description && <p className="states-empty__description">{ description }</p> }
            </div>
            { button && button }
            { children && children }
        </div>
    )
}
