import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";

import { MinMaxLayout } from "../../../../containers/layout";

import Container from '../../../../containers/container';

import { CreateButton, SaveButton, DeleteButton, ProceedButton, AddButton, ExportButton, EmptyButton } from "../../../../components/buttons";

import ContentContainer from "../../../../components/layout/content-container";

import ELearningApi from '../../../../lib/e-learning/e-learning-api';

import CourseRemove from '../../components/popups/course/course-remove';
import CourseEdit from '../../components/popups/course/course-edit';
import CourseModuleAdd from '../../components/popups/course/course-module-add';
import CoursePublish from '../../components/popups/course/course-publish';
import CourseSave from '../../components/popups/course/course-save';

import { stringToDatetimeLocal } from '../../../../lib/helpers/datetime-helpers';

import AlertManager from '../../../../managers/alert/alert-manager';
import Row from '../../../../utils/row';
import LabelContainer from '../../../../components/label-container';
import CancelButton from '../../../../components/buttons/cancel-button';

import Module from './components/module';
import Modules from './components/modules';
import List from '../../../../components/list/list';
import Divider from '../../../../components/divider/divider';
import InputsTextArea from '../../../../components/inputs/inputs-text-area';
import Checkbox from '../../../../components/checkbox';
import PopupContext, { PopupProvider } from '../../../../context/context-popup';
import CourseWorkSessions from '../../components/popups/course-work/course-work-sessions';
import CourseWorkDelete from '../../components/popups/course-work/course-work-delete';

class CourseWork extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            tab: 0,
            courseWork: null,
            course: null,
            confirmManual: false,
            popup: null,
            params: {},
            video_sessions: undefined,
            manualState: {
                question_markings: []
            },
            modules: [],
            crumbs: [
                {
                    name: 'E-Learning',
                    link: '/e-learning'
                },
                {
                    name: 'Course Works',
                    link: '/e-learning/course-works',
                }
            ]
        }
    }

    componentDidMount() {
        this.fetchCourseWork().then(() => {
            this.urlParams();

            const modules = this.state.courseWork?.course_version?.modules;
            const module = modules?.[this.state.tab];

            this.fetchVideoSessions(module);
        });
    }

    deleteCourseWork() {
        this.context.setPopup(<CourseWorkDelete courseId={this.state.course.id} courseWorkId={this.props.courseWorkId}/>)
    }

    fetchVideoSessions(module) {
        new ELearningApi().getCourseWorkSessions(this.props.courseWorkId, {
            module_id: module?.id
        }).then(({ data }) => {
            this.setState({ video_sessions: data });
        });
    }

    urlParams() {
        const queryParameters = new URLSearchParams(window.location.search);
        const module = queryParameters.get('module');
        const modules = this.state.courseWork?.course_version?.modules;

        const nextModules = modules
            .filter(module =>
                this.state.courseWork?.course_module_submissions.find(cms => cms.module_id === module.id) ||
                (this.state.courseWork.module_requiring_assessment_booking === module.id || this.state.courseWork.manual_assessment_bookings.find(m => m.module_id === module.id))
            )
            //.filter(module => !module.sections?.every(section => section.type === 'content'));

        if (module !== null) {
            const indexes = nextModules.map(moduleTab => modules.findIndex(m => m.id === moduleTab.id));
            const moduleIndex = modules.findIndex(m => m.id === module);

            if (indexes.includes(moduleIndex)) this.setTab(moduleIndex);
        }
    }

    setCurrent(modules = [], submittedModules = []) {
        let next = null;

        if(modules[0].hasOwnProperty('config') === false) {
            modules.current = true;
            return modules;
        }

        for (let i = 0; i <  modules.length; i ++ ) {
            let module = modules[i];

            const submittedModule = submittedModules.find(e => e.module_id === module.id);

            if(submittedModule) {
                if(submittedModule.passed === 1) {
                    if(module.type === 'information') {
                        next = module.config?.next_module;
                    }

                    if(module.type === 'dynamic') {
                        next = module.config?.pass_module;
                    }
                }

                if(submittedModule.passed === 0 ) {
                    if(module.type === 'dynamic') {
                        next = module.config?.fail_module;
                    }
                }

            }else {
                if(next === module.id) {
                    module.current = true;
                }
            }
        }

        return modules;
    }

    async fetchCourseWork() {
        return new Promise(async (resolve, reject) => {
            const courseWork = await new ELearningApi().getCourseWork(this.props.courseWorkId);

            if (courseWork) {
                this.setState({ courseWork: courseWork.data }, () => this.fetchCourse());

                const modules = courseWork.data.course_version?.modules;
                const module = modules?.[this.state.tab];

                const modulesWithCurrentSet = this.setCurrent(courseWork.data.course_version?.modules, courseWork.data.course_module_submissions);

                let moduleId = module ? module.id : null, moduleIndex = 0;

                modulesWithCurrentSet.forEach((module, index) => {
                    console.log(module)

                    if(module.current === true) {
                        moduleId = module.id;
                        moduleIndex = index;
                    }
                });

                resolve();
            }

            reject();
        })
    }

    async fetchCourse() {
        const course = await new ELearningApi().fetchCourse(this.state.courseWork.course_id);

        if (course) {
            this.setState({ course: course.data });
        }
    }

    async save(course = null) {

    }

    submitManualAssessment() {
        new ELearningApi().submitModuleAssessment(this.state.courseWork.id, this.state.manualState).then(() => {
            this.fetchCourseWork();

            AlertManager.success('Successfully submitted the manual assessment');
        });
    }

    setManualState(key, value) {
        const state = { ...this.state.manualState }

        if (key === 'question_markings') {
            let markings = state.question_markings;
            const mark = markings.findIndex(d => d.id === value.id);

            if (mark === -1) {
                markings.push(value)
            } else {
                markings[mark] = value;
            }

            state[key] = markings;
        } else {
            state[key] = value;
        }

        this.setState({
            manualState: state,
        })
    }

    resetManualState() {
        this.setState({
            manualState: {
                question_markings: []
            },
        })
    }

    onChange(name, value) {
        this.setState({
            course: {
                ...this.state.course,
                [ name ] : value,
            }
        })
    }

    getStatus() {
        if (this.state.courseWork?.passed === null) return <Container.Text color="#FF7612">In Progress</Container.Text>

        return this.state.courseWork?.passed === 1 ? <Container.Text color="#00C8C3">Passed</Container.Text> : <Container.Text color="#ED2855">Failed</Container.Text>
    }

    setTab(tab) {
        const modules = this.state.courseWork?.course_version?.modules;

        this.setState({ tab }, () => {
            const module = modules?.[tab];

            this.fetchVideoSessions(module);
            this.resetManualState();

            this.setManualState('module_id', module.id);
        });
    }

    renderBottom() {
        const modules = this.state.courseWork?.course_version?.modules;
        const module = modules?.[this.state.tab];

        if (module.type === 'manual' && this.state.courseWork.module_requiring_assessment === module.id) {
            return (
                <List gap="40px" style={{ marginTop: '40px' }}>
                    <InputsTextArea label="Comments to learner"/>
                    <Divider/>
                    <Row gap="40px" justifyContent="space-between">
                        <LabelContainer label="Decision">
                            <Row gap="20px">
                                <EmptyButton onClick={() => this.setManualState('passed', true)} className={this.state.manualState.passed === true ? 'button--green' : ''}>Pass</EmptyButton>
                                <EmptyButton onClick={() => this.setManualState('passed', false)} className={this.state.manualState.passed === false ? 'button--delete' : ''}>Fail</EmptyButton>
                            </Row>
                        </LabelContainer>

                        <LabelContainer label="Confirmation">
                            <Checkbox onChange={() => this.setState({ confirmManual: !this.state.confirmManual })} checked={this.state.confirmManual} text="I can confirm that the learner has carried out there manual assessment that accompanies these questions and that all response have been checked and verified by an approved Assessor for this course."/>
                        </LabelContainer>

                        { this.state.confirmManual && <ProceedButton onClick={this.submitManualAssessment.bind(this)}>Confirm & Submit</ProceedButton> }
                    </Row>
                </List>
            )
        }

        return '';
    }

    render() {
        const modules = this.state.courseWork?.course_version?.modules;
        const module = modules?.[this.state.tab];

        const crumbs = [
            {
                name: 'E-Learning',
                link: '/e-learning'
            },
            {
                name: 'Course Works',
                link: '/e-learning/course-works'
            },
            {
                name: this.state.course?.name,
                link: ''
            }
        ]

        if (module === undefined || this.state.course === null || this.state.courseWork === null) return null;

        const nextModules = modules
            // .filter(module =>
            //     this.state.courseWork?.course_module_submissions.find(cms => cms.module_id === module.id) ||
            //     (this.state.courseWork.module_requiring_assessment_booking === module.id || this.state.courseWork.manual_assessment_bookings.find(m => m.module_id === module.id))
            // )
            // .filter(module => !module.sections?.every(section => section.type === 'content'));

        return (
            <div className='course'>
                <PageLayout
                    topLeft={<Breadcrumb crumbs={crumbs}/>}
                    middle={
                        <Row justifyContent='space-between'>
                            <LabelContainer label="Title">
                                { this.state.course?.name }
                            </LabelContainer>
                            <Row gap="40px">
                                <Row gap="50px">
                                    <LabelContainer label="Session Time">
                                        { String(this.state.courseWork.total_time_mins) }
                                    </LabelContainer>

                                    <LabelContainer label="Status">
                                        { this.getStatus() }
                                    </LabelContainer>
                                </Row>
                                <Row gap="20px">
                                    <ExportButton onClick={() => window.location.href = `/e-learning/course-works/${this.state.courseWork.id}/summary-pdf` }>Export PDF</ExportButton>
                                    <CancelButton mobile={true} onClick={this.deleteCourseWork.bind(this)}/>
                                </Row>
                            </Row>
                        </Row>
                    }

                    bottom={this.renderBottom()}>
                        <MinMaxLayout
                            main={<Modules
                                    resetManualState={this.resetManualState.bind(this)}
                                    setManualState={this.setManualState.bind(this)}
                                    manualState={this.state.manualState}
                                    modules={modules}
                                    tab={this.state.tab}
                                    setTab={this.setTab.bind(this)}
                                    courseWork={this.state.courseWork}
                                    fetchCourseWork={this.fetchCourseWork.bind(this)}
                                    nextModules={nextModules}
                                    videoSessions={this.state.video_sessions}
                                />
                                }
                        />
                </PageLayout>
            </div>
        );
    }
}

const el = document.getElementById('course-work');

if (el)
{
    ReactDOM.render(
    <PopupProvider>
        <CourseWork courseWorkId={el.getAttribute('courseWorkId')}/>
    </PopupProvider>, el);
}
