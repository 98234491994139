import { Component } from 'react';

import SidePopup from '../../../../../components/side-popup';
import SidePopupSection from '../../../../../components/side-popup-section';
import { SaveButton } from '../../../../../components/buttons';

import LabelContainer from '../../../../../components/label-container';

import Row from '../../../../../utils/row';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';
import Dropdown from '../../../../../components/inputs/inputs-dropdown';

import PopupContext from '../../../../../context/context-popup';

import { Editor } from '@tinymce/tinymce-react';

export default class CourseModuleSettings extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
            module: this.props?.module,
        }
    }

    onChange(e) {
        const { name, value } = e.target;
        const module = { ...this.state.module }

        module[name] = value;

        this.setState({ module }, () => {
            console.log(this.state.module.name)
        });
    }

    save() {
        const course = { ...this.props.course };
        const course_version = course.latest_course_versions?.[0];
        const moduleIndex = course_version.modules.findIndex(m => m.id === this.props.module.id);

        course_version.modules[moduleIndex] = this.state.module;

        this.props.save(course).then(() => this.props.onSuccess());
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Module : Information</p>
                </div>

                <SaveButton onClick={this.save.bind(this)}>Save</SaveButton>
            </div>
        );
    }

    onConfigChange(e) {
        const { name, value } = e.target;
        const config = { ...this.state.module?.config } || {};

        config[name] = value;

        this.onChange({
            target: {
                name: 'config',
                value: config,
            }
        });
    }

    render() {
        const contentVersion = this.props.course?.latest_course_versions?.[0] || [];

        let modules = contentVersion?.modules.map((m) => ({
            label: m.name,
            value: m.id,
        }))

        modules = modules.filter((_, index) => index > modules.findIndex(d => d.value === this.state.module.id));

        modules.push({
            label: 'Summary',
            value: null
        })

        return (
            <SidePopup type="grant-details-popup" head={this.head()} onClose={() => this.context.setPopup(null)} content={
                <>
                    <SidePopupSection title="General Details">
                        <Input name="name" label="Name of module [required]" placeholder="Name of Module" value={this.state.module?.name} onChange={this.onChange.bind(this)}/>
                        <LabelContainer label="Description of Module">
                            <Editor
                                onInit={(evt, editor) => this.ref.current = editor}
                                value={this.state.module?.description}
                                onEditorChange={(value, editor) => {
                                    this.onChange({
                                        target: {
                                            name: 'description', 
                                            value: value
                                        }
                                    });
                                }}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }
                                }
                            />
                        </LabelContainer>
                        <LabelContainer label="Estimated time to complete this module [required]">
                            <Row fill>
                                <Input 
                                    label="Input Hours"
                                    mi={0}
                                    type="number"
                                    value={this.state.module?.estimated_hours}
                                    onChange={e => {
                                        this.onChange({
                                            target: {
                                                name: 'estimated_hours', 
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
    
                                <Input 
                                    label="Input Minutes (Max 60)"
                                    min={0}
                                    max={60}
                                    type="number"
                                    value={this.state.module?.estimated_minutes}
                                    onChange={e => {
                                        this.onChange({
                                            target: {
                                                name: 'estimated_minutes', 
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Next Module Navgiation">
                        <div className="module-settings__item">
                            <LabelContainer label="Pass Message">
                                <Input
                                    placeholder="Enter branch label"
                                    value={this.state.module?.config?.branch_label}
                                    name="branch_label"
                                    onChange={this.onConfigChange.bind(this)}
                                />
                                <Dropdown
                                    label="Next Module"
                                    value={modules.find(e => e.value === this.state.module?.config?.next_module)}
                                    items={modules}
                                    reset={false}
                                    onChange={(e) => {
                                        this.onConfigChange({
                                            target: {
                                                name: 'next_module',
                                                value: e.value
                                            }
                                        })
                                    }}
                                />
                            </LabelContainer>
                        </div>
                    </SidePopupSection>
                </>
            }/>
        )
    }
}
