import {Component} from 'react';
import {useMediaQuery} from 'react-responsive';

import ReactDOM from 'react-dom';
import AlertManager from '../managers/alert/alert-manager';

import NewVersion from '../../js/components/new-version';

import ContentApi from '../../js/lib/content/content-api';
import DesktopView from '../../js/components/content-editor/desktop-view';
import TopBar from '../../js/components/content-editor/topbar';

import DistributionPublishing from '../../js/components/content-editor/distribution-publishing';
import DetailsMain from '../../js/components/content-editor/details-main';

import Button from '../../js/components/buttons/button';
import Breadcrumb from '../../js/components/breadcrumb';

import ContentType from './content-type';

import UpdateContentVersionState from "../../js/lib/content/update-content-version-state";

import 'react-dropdown/style.css';
import DistributionApi from "../lib/distributions/distribution-api";
import ContentLock from "../lib/content/content-lock";
import EditorPreview from "../components/content-editor/editor-preview";
import ErrorBoundary from "../lib/errors/error-boundary";

function ComponentWrapper(params) {
    const isTablet = useMediaQuery({query: '(min-width: 968px)'});
    const isPhone = useMediaQuery({query: '(min-width: 576px)'});

    return <ContentEditor isTablet={isTablet} isPhone={isPhone} {...params}/>;
}

class ContentEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: {
                slug_override: false
            },
            tab: true,
            popup: false,
            contentType: {
                sections: []
            },
            body: null,
            versions: [],
            content_errors: {},
            distribution_maps: [],
            distributions: [],
            locked: false,
            contentTypes: [],
            itemSelected : {}

        }
    }

    isTablet = () => useMediaQuery({query: '(min-width: 968px)'})

    onVersionSelect = (e) => {
        if (e.value == "Create New Version") {
            return this.setState((prev) => ({...prev, popup: true}));
        }


        ContentApi.getVersion(this.state.content.id, e.value).then(version => {
            if (_.isArray(version.body)) {
                version.body = {sections: []};
            }
            this.setState({body: version, popup: false});
        });

    }

    confirm() {
        if (this.state.locked) {
            return;
        }
        ContentApi.updateContent(this.state.content.id, this.state.content, errors => {
            this.setState({content_errors: errors}, () => console.log(this.state));
        }).then(() => {
            ContentApi.updateContentVersion(this.state.content.id, this.state.body).then(() => {
                this.setState({content_errors: {}})
                AlertManager.success('Content Saved');
            })
        });
    }

    newUpdateStateHandler() {
        let updater = new UpdateContentVersionState(this.state.body.body.sections);

        updater.onStateChange = sections => {

            this.setState({
                body: {
                    ...this.state.body, body: {
                        ...this.state.body.body,
                        sections: sections
                    }
                }
            });
        };
        return updater
    }

    handleFreeItemDelete(sectionConfig, freeContentIndex) {

        this.newUpdateStateHandler().deleteFreeItem(sectionConfig, freeContentIndex);
    }


    async getContent() {
        let content = await ContentApi.getContent(this.props.contentId);
        this.setState({
            content: content,
            contentType: content.content_type.config,
            body: content.latest_content_versions[0]
        });
        this.handleLock(content);

    }

    handleLock(content) {
        this.locker = new ContentLock(content, this.props.currentUserId);
        if (!this.locker.isLockedByAnotherUser()) {
            return this.locker.lock();
        }
        this.setState({locked: true});

    }

    async getVersions() {
        let versions = await ContentApi.getContentVersions(this.props.contentId);

        this.setState({
            versions: versions
        });
    }

    async componentDidMount() {
        await this.getContent();
        await this.getVersions();
        this.getDistributionMaps();
        this.getDistributions();
        this.getContentTypes();
    }

    getContentTypes() {
        ContentApi.getContentTypes(1, 200).then(response => {

            this.setState({contentTypes: response.data})
        });
    }

    getDistributionMaps() {
        ContentApi.getDistributionMaps(this.props.contentId).then(response => {
            this.setState({distribution_maps: response.data.data});
        });
    }

    getDistributions() {
        DistributionApi.getDistributions().then(distributions => {
            this.setState({distributions});
        });
    }

    addFreeContentItem(index, sectionConfig, type) {
        let order = 0;
        if (this.state.body.body.sections[index]) {
            order = this.state.body.body.sections[index].contents.filter(item => item.fixed == false).length;
        }

        this.handleChange({
            contentItemConfig: {
                type,
                order: order,
            },
            sectionConfig,
            contentIndex: null,
            data: '',
            fixed: false,
            title: ''
        });
    }

    handleFreeContentOrder(sectionConfig, newContents) {
        let updater = new UpdateContentVersionState(this.state.body.body.sections);

        updater.onStateChange = sections => {

            this.setState({
                body: {
                    ...this.state.body, body: {
                        ...this.state.body.body,
                        sections: sections
                    }
                }
            });
        };

        updater.handleFreeContentOrder(sectionConfig, newContents);
    }

    handleFreeContentTitle(contentItemConfig, sectionConfig, contentIndex, title) {
        this.newUpdateStateHandler().handleFreeContentTitle(contentItemConfig, sectionConfig, contentIndex, title);
    }

    handleDisplayed(sectionConfig, e) {

        this.newUpdateStateHandler().handleDisplayed(sectionConfig.name, e);
    }

    handleChange({contentItemConfig, sectionConfig, contentIndex, data, fixed}) {
        this.newUpdateStateHandler().handleUpdate(contentItemConfig, sectionConfig, contentIndex, data, fixed);
    }

    closePopup() {
        this.setState((prev) => ({...prev, popup: false}))
    }

    handleContentChange(field, data) {

        if (field === 'meta') {
            return this.setState({
                content: Object.assign({}, this.state.content, {
                    meta: Object.assign({}, this.state.content.meta, data)
                })
            })
        }

        if (field === 'tags') {
            return this.setState({
                content: Object.assign({}, this.state.content, {
                    tags: data
                })
            }, () => console.log(this.state.content));
        }

        this.setState({
            content: Object.assign({}, this.state.content, {
                [field]: data
            })
        });

    }

    // componentDidCatch(error, errorInfo) {
    //     console.log(error);
    // }


    async handleCreateVersionEvent(versionId) {
        await this.getVersions();
        this.onVersionSelect({value: versionId})
    }

    async handleDelete() {
        ContentApi.deleteContent(this.state.content.id).then(response => {
            if (response.status === 200) {
                AlertManager.success('Content Deleted');
                setTimeout(() => window.location = '/content/search', 1000);
            } else {
                AlertManager.error('Could not delete this content');
            }
        })
    }

    handleDropEnter(section, content) {
        const itemSelected = this.state.itemSelected;

        this.setState({ itemSelected : { section , content }});
    }


    handleDropLeave(section, content) {
        // this.setState({itemSelected : {}});
    }

    render() {
        if (!this.props.isPhone) return <h1>Not avaliable on phone</h1>;

        return (
            <>
                {this.state.popup &&
                <NewVersion onSuccess={this.handleCreateVersionEvent.bind(this)} contentId={this.props.contentId}
                            setPopup={this.closePopup.bind(this)}/>}
                <ErrorBoundary text={'There is an issue with this Content Type. Please check the configuration'}>
                    <div className="content-editor">
                        <div className="website__topbar">
                            <Breadcrumb crumbs={[{
                                name: 'Content',
                                link: '/content/search'
                            }, {
                                name: this.state.content.title,
                                link: '#'
                            }]}/>
                            <TopBar
                                currentContentVersion={this.state.body ? this.state.body.id : null}
                                dropdownOptions={this.state.versions}
                                onChange={this.onVersionSelect.bind(this)}
                                confirm={this.confirm.bind(this)}
                                onDelete={this.handleDelete.bind(this)}
                                content={this.state.content}
                            />
                        </div>
                        <div className="website__content">
                            <div className="content-editor__top">
                                {
                                    this.state.locked === true &&
                                    <p>Locked: {this.state.content.locked_by.name} is currently editing this page</p>
                                }

                                {/*<a href="#">View History Of Changes</a>*/}
                            </div>
                            <div className="content-editor__details content-editor__container">
                                {
                                    this.state.locked &&
                                    <div className={'content-editor__lock-overlay'}></div>
                                }
                                <DetailsMain
                                    errors={this.state.content_errors}
                                    content={this.state.content}
                                    onChange={this.handleContentChange.bind(this)}
                                    contentType={this.state.contentType}
                                />

                                <DistributionPublishing
                                    distrutionMaps={this.state.distribution_maps}
                                    distributions={this.state.distributions}
                                    versions={this.state.versions}
                                    content={this.state.content}
                                    onChange={() => {
                                        this.getDistributionMaps();
                                    }}
                                />
                            </div>
                        </div>
                        {
                            (this.state.body && this.state.contentType) &&
                            <div className="editor-tab content-editor__container">
                                {
                                    this.state.locked &&
                                    <div className={'content-editor__lock-overlay'}></div>
                                }
                                {!this.isTablet && <div className="tab-selector">
                                    <Button
                                        className={`button__fit button__small ${this.state.tab ? "button--alternate" : "button--idle"}`}>Editor</Button>
                                    <Button
                                        className={`button__fit button__small ${this.state.tab ? "button--alternate" : "button--idle"}`}>Preview</Button>
                                </div>}

                                {this.isTablet ? <DesktopView state={this.state}
                                                            content={this.state.content}
                                                              addFreeContentItem={this.addFreeContentItem.bind(this)}
                                                              onFreeItemDelete={this.handleFreeItemDelete.bind(this)}
                                                              handleFreeContentTitle={this.handleFreeContentTitle.bind(this)}
                                                              handleFreeContentOrder={this.handleFreeContentOrder.bind(this)}
                                                              handleChange={this.handleChange.bind(this)}
                                                              handleDisplayed={this.handleDisplayed.bind(this)}
                                                              onDropEnter={this.handleDropEnter.bind(this)}
                                                              onDropLeave={this.handleDropLeave.bind(this)}
                                                              selectedItem={this.state.itemSelected}
                                    />
                                    : <>

                                        {this.state.tab ? <div className="content-editor__inner content-editor__editor">
                                                <p className="content-editor__title">Content Editor</p>
                                                <div className="content-editor__content">
                                                    <Editor content={this.state.content} addFreeContentItem={this.addFreeContentItem.bind(this)}
                                                            handleFreeContentOrder={this.handleFreeContentOrder.bind(this)}
                                                            state={this.state}/>
                                                </div>
                                            </div> :

                                            <div className="content-editor__inner content-editor__preview">
                                                <p className="content-editor__title">Web Page Preview</p>
                                                <div className="content-editor__content">
                                                    <EditorPreview
                                                        distributions={this.state.distributions}
                                                        contentVersion={this.state.contentVersion}
                                                    />
                                                </div>
                                            </div>}
                                    </>}

                            </div>
                        }

                    </div>
                </ErrorBoundary>

            </>
        );
    }
}

export default ComponentWrapper;

let el = document.getElementById('content-editor');

if (el) {
    ReactDOM.render(<ComponentWrapper currentUserId={parseInt(el.getAttribute('data-user-id'))}
                                      contentId={el.getAttribute('data-content-id')}/>, el);
}
