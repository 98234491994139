import { useEffect , useState, useCallback } from 'react'

import Display from './browse/display';

import { ProceedButton } from '../../buttons';

import '@uppy/image-editor/dist/style.css'
import axios from 'axios';
import LoadingIcon from "../../ui/loading-icon";
import SearchBox from '../../content-search/search-box';
import FileTypes from '../../../lib/files/file-types';

const FileIcons = {
    'application/pdf': 'pdf',
    'application/msword': 'document-icon',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document-icon',
    'text/plain': 'txt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/csv': 'csv',
    'image/jpeg' : 'document-icon',
    'image/png' : 'document-icon',
    'pdf': 'pdf',
    'png': 'document-icon',
    'jpg': 'document-icon',
    'docx': 'document-icon',
    'doc': 'document-icon',
    'csv': 'csv'
}

export default ({ onResourceSelect, path, searchPath = null, readOnly, deletePath, showDelete, onBrowserSelect = () => {} }) => {
    const [ files, setFiles ] = useState([]);
    const [ selected, setSelected ] = useState(null);
    const [ busy, setBusy ] = useState(true);

    const [ search, setSearch ] = useState('');

    const onSelect = (item) => {
        if (item.id == selected?.id) return setSelected(null);

        item.readOnly = readOnly;
        item.downloadOnly = false;
        item.deletePath = `${deletePath}/${item?.id}`;
        item.imagePreview = true;

        onBrowserSelect(item);

        setSelected(item);
    }

    const getFiles = () => {
        axios.get(path).then((response) => {
            const { data } = response.data;
            setSelected(null);

            setTimeout(() => {
                setBusy(false);
            }, 500);

            setFiles(data)
        })
    }

    const searchFiles = () => {
        axios.post(searchPath, {
            term: search
        }).then((response) => {

            setSelected(null);

            setTimeout(() => {
                setBusy(false);
            }, 500);

            setFiles(response?.data);
        })
    }

    const onChange = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
      getFiles();
    }, []);

    useEffect(() => {
        get();
    }, [ search ])

    const get = () => {
        if (search?.length > 0) {
            searchFiles();
        } else {
            getFiles();
        }
    }

    return (
        <div class="images-browse">
            <div class="images-browse__section">
                <div className="images-browse__outer">
                    <SearchBox onChange={onChange}/>
                    <div class="images-browse__list">
                        {
                            busy === false &&
                            <>
                            {
                                Array.isArray(files) && files.map((obj, index) => {
                                    const item = obj.hasOwnProperty('file') ? obj.file : obj;

                                    const className = "images-item " + (obj.id == selected?.id ? 'images-item--selected' : '')

                                    const name = item.name.split('.');
                                    const fileName = name[0].slice(0, 12) + '.' + name[name.length - 1];

                                    return (
                                        <div key={index} class={className} onClick={() => onSelect(obj)}>
                                            <img src={`/assets/file-types/${FileIcons[item.mime_type]}.svg`} />
                                            <div class="images-item__select">
                                                {obj.id == selected?.id ? 'Selected' : fileName}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                                {
                                    Array.isArray(files) && files.length === 0 &&
                                        <div className={'images-browse__empty'}>
                                            Nothing uploaded yet, click 'Upload' to add files
                                        </div>
                                }
                            </>

                        }
                        {
                            busy === true &&
                            <div className={'images-browse__loading'}>
                                <LoadingIcon />
                            </div>
                        }

                    </div>
                </div>
            </div>
            { selected !== null && <div class="images-browse__box">
                <Display img={selected.hasOwnProperty('file') ? selected.file : selected} showDelete={showDelete} icon={`/assets/file-types/${FileIcons[selected.hasOwnProperty('file') ? selected.file.mime_type : selected.mime_type]}.svg`} downloadPath={selected.downloadPath} imagePreview={selected.imagePreview} readOnly={false} onDelete={() => getFiles()} deletePath={selected.deletePath} downloadOnly={selected.downloadOnly} onUseResource={() => onResourceSelect(selected)}/>
            </div> }
            {/* <div className="images-browse__bottom">
                { selected !== null && <ProceedButton>Use selected file</ProceedButton> }
            </div> */}
        </div>
    )
}



