import Dropdown from '../../../../../../components/dropdown-content/dropdown';

import {
    AddButton,
    DuplicateButton,
    DeleteButton,
} from '../../../../../../components/buttons';

import QuestionTypeRichText from './question-type-rich-text';
import QuestionTypeImage from './question-type-image';
import QuestionTypeVideo from './question-type-video';
import QuestionTypeHtml from './question-type-html';
import QuestionTypeGallery from './question-type-gallery';
import QuestionTypeCta from './question-type-cta';
import QuestionTypeCheckbox from './question-type-checkbox';
import QuestionTypeAttachDocs from './question-type-attach-docs';
import QuestionTypeRadioButtons from './question-type-radio-buttons';
import QuestionTypeSlidingScale from './question-type-sliding-scale';
import QuestionTypeTable from './question-type-table';
import QuestionTypeTextResponse from './question-type-text-response';

// Dynamic
import QuestionTypeSingleChoice from './question-type-single-choice';
import QuestionTypeMultipleChoice from './question-type-multiple-choice';
import QuestionTypeMultipleChoiceImage from './question-type-multiple-choice-image';
import QuestionTypeListRanking from './question-type-list-ranking';
import QuestionTypeTextMatching from './question-type-text-matching';
import QuestionTypeFillBlank from './question-type-fill-blank';

const questionTypeComponents = {
    'rich-text': QuestionTypeRichText,
    'image': QuestionTypeImage,
    'video': QuestionTypeVideo,
    'html': QuestionTypeHtml,
    'gallery': QuestionTypeGallery,
    'cta': QuestionTypeCta,
    'checkbox': QuestionTypeCheckbox,
    'document-upload': QuestionTypeAttachDocs,
    'radio-buttons': QuestionTypeRadioButtons,
    'sliding-scale': QuestionTypeSlidingScale,
    'text-response': QuestionTypeTextResponse,
    'table': QuestionTypeTable,
    'single-choice': QuestionTypeSingleChoice,
    'multi-choice': QuestionTypeMultipleChoice,
    'multi-choice-image': QuestionTypeMultipleChoiceImage,
    'list-ranking': QuestionTypeListRanking,
    'text-matching': QuestionTypeTextMatching,
    'fill-blank': QuestionTypeFillBlank,
}

export default function QuestionDropdown({
    question,
    questions,
    index,
    onQuestionRemove,
    onQuestionChange,
    setPopup,
    style = {}
}) {
    const head = () => {
        return (
            <div className="utils__row utils__gap--20">
                <DeleteButton mobile={true} onClick={onQuestionRemove}/>
            </div>
        )
    }

    const getNextQuestion = () => {
        const next_question = question.config?.next_question;
        if (next_question === null || next_question === undefined) return null;

        const q = questions[next_question];

        if (q) {
            return {
                label: `${index + 1}) ${q.title}`,
                value: next_question,
            }
        }

        return { label: '', value: '' }
    }

    const getMappedQuestions = () => {
        let items = [ ...questions ];

        items = items
            .map((q, i) => ({
                label: `${i + 1}) ${q.title} `,
                value: i,
            }))
            .filter(item => {
                const value = item.label.split(')')[0] - 1;

                return value > index
            })

        return items;
    }

    const getQuestionByType = () => {
        const Component = questionTypeComponents[question.type];

        return <Component question={question}
                    questions={questions}
                    onQuestionChange={onQuestionChange}
                    getMappedQuestions={getMappedQuestions}
                    getNextQuestion={getNextQuestion}
                    setPopup={setPopup}
                    index={index}/>
    }

    return (
        <Dropdown title={question?.title || question?.type} head={head()} className="grant-question-block" style={style}>
            <Dropdown.Content>
                { getQuestionByType() }
            </Dropdown.Content>
        </Dropdown>
    )
}
