import { useState, useContext } from 'react';

import LabelContainer from '../label-container';
import ErrorContainer from '../error-container';

import ImagesPopup from "../popups/images/images-popup";
import PopupContext from '../../context/context-popup';

export default (props) => {
    const { label, placeholder, fill, name, onChange } = props;

    const { addPopup, closePopup } = useContext(PopupContext);

    const show = () => {
        addPopup((index) => {
            return <ImagesPopup
                onClose={() => closePopup(index)}
                onResourceSelect={r => {
                    closePopup(index);

                    onChange({
                        target: {
                            name: name,
                            value: r.resource.file_name
                        }
                    })
                }}
                onlyShow={['browse', 'upload']}
            />
        })
    }

    return (
        <LabelContainer label={label} placeholder={placeholder} fill={fill}>
            <ErrorContainer errors={props?.errors} fill={fill}>
                <div class="input-outer__select">
                    <input className="inputs-text" { ...props }/>
                    <button onClick={show}>Select</button>
                </div>
            </ErrorContainer>
        </LabelContainer>
    )
}
