import { useState } from 'react';

import Popup from '../../popup';

import TabBar from '../../tab-bar';

import Upload from './file-uploader';
import Browser from './file-browse';

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default ({ onlyShow = ['upload', 'browse'], showDelete = false, deleteEvent, extraData = {}, deletePath, readOnly = null, onResourceSelect, onBrowserSelect = () => {}, fileTypes = [], params, onClose, toggled = false, endpoint, path, searchPath = null }) => {
    const [ tab, setTab ] = useState(0);

    params = { path, params : params };


    const tabs = [
        { type: 'browse', Component: Browser },
        { type: 'upload', Component: Upload },
    ].filter(t => onlyShow.includes(t.type));

    if (!toggled) return '';

    const pillsNames = (item) => {
        if(item.type === 'browse') {
            return 'Browse Uploaded Files';
        }
        if(item.type === 'upload') {
            return 'Upload a File'
        }
    }

    return (
        <Popup type="images-popup">
            <div class="popup__inner">
                <div class="images-popup__head">
                    <div onClick={onClose} class="images-popup__close">
                        <img src="/assets/rightArrow.svg" />
                        Close
                    </div>
                    <h2>Media Manager</h2>
                </div>

                <TabBar tabs={tabs.map(item => pillsNames(item))} tab={tab} setTab={setTab}/>

                { tabs.map(({ Component }, index) => {
                    if (tab == index) return <Component key={index} extraData={extraData} deleteEvent={deleteEvent} deletePath={deletePath} onBrowserSelect={onBrowserSelect} searchPath={searchPath} readOnly={readOnly} showDelete={showDelete} onResourceSelect={onResourceSelect} fileTypes={fileTypes}  endpoint={endpoint} path={path} params={params}/>
                }) }
            </div>
        </Popup>
    )
}
