import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Breadcrumb from "../../../../components/breadcrumb";
import PageLayout from "../../../../containers/layout/page-layout";
import { MinMaxLayout } from "../../../../containers/layout";

import ContentContainer from "../../../../components/layout/content-container";

import { CreateButton } from '../../../../components/buttons';

import Sponsors from '../../../../components/sponsors';

import Navigation from '../../../grants/components/grants-application-navigation';

import SearchBarItem from '../../../../components/search/search-bar-item';

import ELearningPaths from '../../components/e-learning-path-list';
import ELearningView from '../../components/popups/e-learning-view';
import ELearningCreate from '../../components/popups/e-learning-create';
import ELearningAPI from '../../../../lib/e-learning/e-learning-api';
import ELearningFilters from '../../components/e-learning-path-filters';

import PopupContext, { PopupProvider } from '../../../../context/context-popup';

const crumbs = [
    {
        name: 'E-Learning',
        link: '/e-learning'
    },
    {
        name: 'Learning Paths',
        link: '/e-learning/learning-paths'
    }
]

class LearningPaths extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.state = {
           paths: [],
           meta: {},
           popup: null,
           loading: true,
           currentPage: 1,
           order_by: null,
           filter_by: null,
           direction: {
               label: 'Descending',
               value: 'desc'
           },
           term: '',
        };
    }

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        let params = {}

        this.setState({ loading: true });

        if (this.state.order_by !== null) {
            params.order_by = this.state.order_by.value;
        }

        if (this.state.filter_by !== null) {
            params.filter_by = this.state.filter_by.value;
        }

        if (this.state.direction !== null) {
            params.direction = this.state.direction.value;
        }

        if (this.state.term?.length > 0) {
            new ELearningAPI().searchLearningPaths({ term: this.state.term }).then(({ data, meta }) => {
                this.setState({ paths: data, meta: meta, loading: false });
            })
        }

        new ELearningAPI().getLearningPaths(this.state.currentPage, 20, params).then(({ data, meta }) => {
            this.setState({ paths: data, meta: meta, loading: false });
        });
    }

    createPath() {
        const { setPopup } = this.context;

        setPopup(<ELearningCreate />);
    }

    setPage(index) {
        this.setState(state => ({ currentPage: index }), () => this.fetch());
    }

    goBack() {
        if (this.state.currentPage == 1) return;

        this.setPage(this.state.currentPage - 1);
    }

    goNext() {
        if (this.state.currentPage == this.state.meta.last_page) return;

        this.setPage(this.state.currentPage + 1);
    }

    onSearchChange(e) {
        const { value } = e.target;

        this.setState({ term: value }, () => {
           this.fetch();
        });
    }

    onFilterBy(e) {
        this.setState({ filter_by: e }, () => this.fetch());
    }

    onDirection(e) {
        this.setState({ direction: e }, () => this.fetch());
    }

    onSortBy(e) {
        this.setState({ order_by: e }, () => this.fetch());
    }

    render() {
        return (
            <ContentContainer
                title={'Learning Paths'}
                count={this.state.paths.length}
                icon={<img src="/assets/eb-portal-menu-icons/eb-portal-menu-learning-paths.svg"/>}

                top={<div className="grant-index-top utils__rowSpaceBetween">
                    <SearchBarItem showButton={false} color="search-bar--white" onChange={this.onSearchChange.bind(this)}/>
                    <CreateButton onClick={this.createPath.bind(this)}>Create Path</CreateButton>
                </div>}

                header={<><ELearningFilters
                    onFilterBy={this.onFilterBy.bind(this)}
                    onSortBy={this.onSortBy.bind(this)}
                    sortBy={this.state.order_by}
                    filterBy={this.state.filter_by}
                    onDirection={this.onDirection.bind(this)}
                    direction={this.state.direction}
                /></>}

                bottom={<Navigation back={this.goBack.bind(this)}
                                    next={this.goNext.bind(this)}
                                    setPage={this.setPage.bind(this)}
                                    meta={this.state.meta}
                                    backDisabled={this.state.currentPage == 1}
                                    nextDisabled={this.state.currentPage == this.state.meta?.last_page}/>}>
                    <ELearningPaths paths={this.state.paths} fetch={this.fetch.bind(this)}/>
            </ContentContainer>
        );
    }
}

const ELearningIndex = () => {
    return (
        <PageLayout
            topLeft={<Breadcrumb crumbs={crumbs}/>}
            topRight={<Sponsors/>}>
            <div className={'e-learning-index'}>
                <MinMaxLayout main={<LearningPaths/>}/>
            </div>
        </PageLayout>

    );
}

const el = document.getElementById('learning-paths');

if (el)
{
    ReactDOM.render(
        <PopupProvider>
            <ELearningIndex/>
        </PopupProvider>
    , el);
}
